import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router'

import { AppContext } from 'components/App'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import translate from 'core/translate'
import { headerHeight } from 'components/Header/styles.js'

import { css } from 'aphrodite'
import styles from './styles.js'

export const anchors = {
    aboutUs: 'aboutUs',
    contacts: 'contacts'
}

export const getPathname = (pathname = '') => pathname.replace(/^\/+|\/+$/g, '')

const Menu = ({ type, menu, id, className, onClick = () => {}, ...props }) => {
    const appContext = useContext(AppContext)

    const defaultMenuItems = [
        {
            label: translate('menu.aboutUs'),
            href: `#${anchors.aboutUs}`,
            anchorId: anchors.aboutUs,
            onClick: null,
            active: true,
            isHide: window.spconfig.hideFooterItems[0]
        },
        // {
        //     label: translate('menu.prices'),
        //     href: 'prices',
        //     onClick: null,
        //     active: true
        // },
        {
            label: translate('menu.contacts'),
            href: `#${anchors.contacts}`,
            anchorId: anchors.contacts,
            onClick: null,
            active: true,
            isHide: window.spconfig.hideFooterItems[1]
        },
        {
            label: translate('menu.documentation'),
            href: 'https://wiki.smartplayer.org/index.php/Main_Page',
            target: '_blank',
            onClick: null,
            simple: true,
            isHide: window.spconfig.hideFooterItems[2]
        },
        id === 'main' && {
            label: translate('priceListTariffs'),
            href: window.spconfig.priceListTariffs,
            target: '_blank',
            onClick: null,
            simple: true,
            isHide: window.spconfig.hideFooterItems[3]
        }
    ]

    const getMenuItems = (pathname) => {
        if (menu) {
            return menu
        }

        if (pathname && !anchors[pathname]) {
            return defaultMenuItems.map((item) => {
                if (item.anchorId) {
                    return {
                        active: true,
                        label: item.label,
                        href: '/',
                        anchor: item.anchorId,
                        onClick: () => appContext.onSetAnchor(item.anchorId)
                    }
                }

                return item
            })
        }

        return defaultMenuItems
    }

    const onClickHandler = (e, item) => {
        onClick(e)

        if (item.onClick) {
            item.onClick(e)
        }
    }

    const location = props.router.location

    if (id === 'modules') {
        return (
            <ul className={css(styles.menuWrapper, styles[`${type}MenuWrapper`], className)}>
                <li className={css(styles.menuItem, styles[`${type}MenuItem`])} key={'educationMinistryLi'}>
                    <span className={css(styles.moduleItem)}>{translate('modules')}</span>
                    <Link
                        to="/education"
                        className={css(styles.menuItemLink, styles[`${type}MenuItemLink`])}
                        id={'anchor_educationMinistry'}
                        onClick={(e) => onClickHandler(e, null)}
                        data-slide={null}
                        target={null}
                    >
                        {translate('informationManagement')}
                    </Link>
                </li>
            </ul>
        )
    }

    return (
        <ul className={css(styles.menuWrapper, styles[`${type}MenuWrapper`], className)}>
            {(() => {
                const pathname = getPathname(location.pathname)
                const menuItems = getMenuItems(pathname)

                return menuItems.map((item) => {
                    const isMenuItemActive = item.href === pathname
                    if (item.label === translate('menu.aboutUs') && item.isHide) {
                        return
                    }
                    if (item.label === translate('menu.contacts') && item.isHide) {
                        return
                    }
                    if (item.label === translate('menu.documentation') && item.isHide) {
                        return
                    }
                    if (item.label === translate('priceListTariffs') && item.isHide) {
                        return
                    }
                    return (
                        <li
                            className={css(styles.menuItem, styles[`${type}MenuItem`])}
                            key={`${item.label}_${type}_${id}`}
                        >
                            {item.anchorId && (
                                <AnchorLink
                                    className={css(styles.menuItemLink, styles[`${type}MenuItemLink`])}
                                    id={`anchor_${item.anchorId}`}
                                    href={item.href}
                                    offset={headerHeight}
                                    data-slide={item.anchor}
                                    onClick={(e) => onClickHandler(e, item)}
                                >
                                    {item.label}
                                </AnchorLink>
                            )}
                            {!item.anchorId && !item.simple && (
                                <Link
                                    to={item.href}
                                    className={css(
                                        styles.menuItemLink,
                                        styles[`${type}MenuItemLink`],
                                        isMenuItemActive && item.active && styles.menuItemActive
                                    )}
                                    data-slide={item.anchor}
                                    onClick={(e) => onClickHandler(e, item)}
                                    id={`anchor_${item.anchor}`}
                                    target={item.target}
                                >
                                    {item.label}
                                </Link>
                            )}
                            {item.simple && (
                                <a
                                    href={item.href}
                                    className={css(styles.menuItemLink, styles[`${type}MenuItemLink`])}
                                    target={item.target}
                                >
                                    {item.label}
                                </a>
                            )}
                        </li>
                    )
                })
            })()}
        </ul>
    )
}

Menu.propTypes = {
    type: PropTypes.oneOf(['horizontal', 'vertical']),
    menu: PropTypes.array,
    className: PropTypes.object,
    onClick: PropTypes.func
}

Menu.defaultProps = {
    onClick: () => {}
}

export default withRouter(Menu)
