import themeConfig from 'theme'

export default {
    ru: {
        supportForNewProject: 'Свяжитесь с нами по вопросам сотрудничества и запуска новых проектов',
        companyName: 'Название компании',
        companySite: 'Сайт компании',
        priceListTariffs: 'Прайс лист и тарифы',
        supportForExistedProject: 'Свяжитесь с нами по вопросам технической поддержки реализованных проектов',
        supportAboutProducts: 'По данному телефону вы можете запросить стоимость программного обеспечения',
        mainDescription:
            'Единая платформа для реализации мультимедийных проектов любой сложности и централизованного управления устройствами в местах продаж.',
        'menu.aboutUs': 'О нас',
        'menu.prices': 'Цены',
        'menu.contacts': 'Контакты',
        'menu.documentation': 'Документация',
        try: 'Попробовать',
        tryForFree: 'Попробуй бесплатно',
        theyTrustUs: 'Нам доверяют',
        solutions: 'Какие задачи решает SmartPlayer?',
        'solutions.slide1.title': 'Видеостена',
        'solutions.slide1.text1': 'Эффективный канал коммуникации с посетителями',
        'solutions.slide1.text2':
            'Интеграция с кассовыми системами для автоматического обновления информации о наличии товара изменении цен',
        'solutions.slide1.text3':
            'Синхронизация контента “эффект рыбки плавающей с экрана на экран” помогает привлечь дополнительное внимание к акции на менюборде',
        'solutions.slide2.title': 'Видеонаблюдение',
        'solutions.slide2.text1': 'Объединение всех камер в одну надежную систему',
        'solutions.slide2.text2': 'Улучшения качества обслуживания клиентов',
        'solutions.slide2.text3': 'Обеспечение безопасности',
        'solutions.slide2.text4': 'Оценка посещаемости магазинов',
        'solutions.slide2.text5': 'Контроль кассовой зоны',
        'solutions.slide2.text6': 'Таргетирование рекламы на экранах',
        'solutions.slide2.text7': 'Создание портрета аудитории',
        'solutions.slide3.title': 'Тач панели',
        'solutions.slide3.text1':
            'Дают возможность использовать omnichanel механики за счет интеграции с e-commerce системами. Возможность заказа товара, которого нет в наличии из другого магазина или со склада.',
        'solutions.slide3.text2':
            'Позволяют вовлечь пользователей в самостоятельное изучение товара, делать up-sell, cross-sell за счет возможности просмотра более дорогих версий товара и показа рекомендаций',
        'solutions.slide4.title': 'Прикассовый экран',
        'solutions.slide4.text1':
            'Дополнительная монетизация за счёт интеграции с программатик платформами Yandex/Mail.ru/Between Exchange',
        'solutions.slide4.text2': 'Социальные сервисы, к примеру приложение для сурдоперевода',
        'solutions.slide4.text3': 'Анализ лояльности/ Оценка качества обслуживания',
        'solutions.slide4.text4':
            'Возможность показа спец. Предложений/акций. Рост продаж демонстрируемых товаров до 5%',
        'solutions.slide4.text5':
            'Возможность показа чека/ суммы (интеграция с кассовой системой) на экране для дополнительного привлечения внимания на экран',
        'solutions.slide4.text6':
            'Увеличение среднего чека за счёт показа персонализированных предложения базируясь на предыдущих покупках и текущей корзине (интеграция с системой лояльности)',
        'solutions.slide5.title': 'Экраны + Фоновая музыка',
        'solutions.slide5.text1':
            'Возможность вывода с экрана в зале дополнительной, независимой от видеоряда, звуковой дорожки с фоновой музыкой. Нет необходимости в дополнительном устройстве или лицензии.',
        'solutions.slide6.title': 'Витринные дисплеи',
        'solutions.slide6.text1':
            'Привлекают внимание, увеличивают трафик в точке продаж, повышают узнаваемость и запоминаемость бренда',
        'solutions.slide6.text2':
            'За счет повышенной яркости и функции динамической регулировки подсветки, могут эффективно работать как в светлое, так и в темное время суток',
        whySmartplayer: 'Почему SmartPlayer?',
        contacts: 'Контакты',
        toContactUs: 'Связаться с нами',
        name: 'имя',
        email: 'email',
        phone: 'Телефон',
        mail: 'Почта',
        message: 'сообщение',
        send: 'Отправить',
        agreeText: 'Я согласен на обработку персональных данных',
        messageIsSent: 'Сообщение отправляется...',
        messageSendError: 'Сообщение не отправлено. Напишите нам напрямую на почту:',
        messageSent: 'Сообщение отправлено',
        contactsForCommunication: 'Контакты для связи',
        address: 'Адрес',
        smartplayerOffice: 'г. Москва, ул. Дербеневская д. 20 стр. 26, <br /> подъезд 6, этаж 4, кабинет №5',
        schedule: 'Режим работы',
        'mon.fri': 'пн-пт',
        'sat.sun': 'сб-вс',
        dayOff: 'Выходной',
        videowall: 'Видеостена',
        music: 'Музыка',
        CCTV: 'Видеонаблюдение',
        menuBoards: 'Менюборды',
        touchPanels: 'Тач-панели',
        checkoutScreens: 'Прикассовые экраны',
        displayDisplays: 'Витринные дисплеи',
        // Prices
        'prices.monthly': 'Месячная',
        'prices.early': 'Годовая',
        'prices.subscription': 'подписка',
        'prices.forOneDevice': 'для 1 устройства',
        'prices.smartSoundDesc': 'Воспроизведение аудио и управления фоновыми звуками',
        'prices.smartScreenDesc': 'Управление сложным визуальным контентом на большом количестве экранов',
        'prices.smartTouchDesc':
            'Управление интерактивным контентом, создание и редактирование мультимедийных трансляций',
        'prices.smartVideoWallDesc': 'Управление визуальным контентом на видеостене',
        'prices.smartVideoAnalyticsDesc': 'Анализ видеоданных и управление сложным визуальным контентом',
        'prices.smartVideoAnalyticsAndTouchDesc':
            'Анализ видеоданных и управление сложным визуальным и интерактивным контентом',
        'prices.chooseAService': 'Выберите услугу',
        devices: 'Устройств',
        login: 'Войти',
        demoModalDescription: `Хочу <span style="color: ${themeConfig.colors.blue}">посмотреть возможности</span> сервиса`,
        requestADemo: 'Запросить демо',
        demostrationDescription: 'Эксперт проведет демонстрацию SmartPlayer в наших лучших традициях',
        'demo.description1': 'подробно и человеческим языком',
        'demo.description2': 'с учетом ваших конкретных задач на примерах',
        'demo.description3': 'ответит на любые вопросы',
        'whatASmartplayer.title': 'Что такое Digital Signage и зачем нужен SmartPlayer?',
        'whatASmartplayer.p1': `<span style="color: ${themeConfig.colors.blue}">Digital Signage</span> - это технология предоставления необходимой информации через цифровые устройства отображения, установленные в общественных местах.`,
        'whatASmartplayer.p2':
            'Цифровые устройства отображения это - экраны, менюборды, светодиодные LED экраны, интерактивные киоски, видеостены, планшеты, аудио оборудование и т.д.',
        'whatASmartplayer.p3':
            'На данный момент данная технология активно развивается и эффективно применяется во многих отраслях. Она заменяет современный формат отображения рекламы - статичные Pos материалы, лайтбоксы.',
        'whatASmartplayer.descriptionTitle': 'Популярные решения',
        'whatASmartplayer.listItem1': 'Трансляция Вашего рекламного видеоконтента',
        'whatASmartplayer.listItem2': 'Трансляции аудиодорожек по расписанию',
        'whatASmartplayer.listItem3': 'Отображение стоимости Ваших продуктов или акционных предложений',
        'whatASmartplayer.listItem4': 'Киоски самообслуживания или навигация в Торговом центре',
        'whatASmartplayer.listItem5': 'Табло с обменом валют или расписание рейсов',
        'whatASmartplayer.bigDescription': `Платформа <span style="color: ${themeConfig.colors.blue}">SmartPlayer</span> является универсальным программным обеспечением для реализации данных решений. С помощью нашего программного обеспечения Вы сможете оперативно загружать необходимый контент из любой точки мира и следить за работоспособностью оборудования.`,

        // Табы
        'tabs.title': 'Как работает система SmartPlayer',

        // Создание
        'tabs.create.title': 'Загрузите необходимый контент в редактор и отредактируйте его',
        'tabs.create.listTitle': 'Возможности:',
        'tabs.create.listItems':
            'Многозонность<br/>Бегущая строка<br/>RSS лента<br/>HTML<br/>Отображение web-frame страниц',

        // Управление
        'tabs.control.title': 'Добавьте отредактированный контент в расписание',
        'tabs.control.listTitle': 'Возможности:',
        'tabs.control.listItems':
            'Создание трансляций<br/>Показ контента по расписанию<br/>Группировка устройств по местам вещания',

        // Проигрывание
        'tabs.play.title': 'Установите лицензии и загрузите трансляции на устройства',
        'tabs.play.listTitle': 'Возможности:',
        'tabs.play.listSubTitle': 'Управление в 2 вариантах:',
        'tabs.play.listItem1': 'Облачное SaaS<br/>Собственный сервер',
        'tabs.play.listItem2': 'Кроссплатформенность<br/>Автобновление',

        // Аналитика
        'tabs.analytic.title': 'Сделайте разграничение прав доступа и следите за мониторингом и аналитикой',
        'tabs.analytic.listTitle': 'Возможности:',
        'tabs.analytic.listItems': `Разграничение по ролям<br/>Разграничение по зонам контента<br/>Разграничение по устройствам и местам вещания`,

        watchAVideo: 'Посмотри видео',
        feedbackButtonText: 'Написать нам',
        feedbackTitle: 'Спасибо за отзыв!',
        informationManagement: 'Образовательная система отображения и управления контента',
        modules: 'Модули',
        informaM1: 'Образовательная система отображения и управления контента',
        informaM2: 'Состав подсистемы',
        informaM3:
            'Образовательная система отображения и управления контента обеспечивает проигрывание мультимедийного контента на устройствах (далее – средство отображения информации). Средства отображения информации могут представлять собой различного рода устройства, работающих под операционными системами Tizen OS версии 6.0 и выше, Android OS версии 5.0 и выше.',
        informaM4: 'Подсистема состоит из следующих модулей:',
        informaM5:
            'Модуль «Сервер управления средствами отображения» предназначен для регистрации и централизованного управления модулями «Смарт-клиент», устанавливаемых на средствах отображения информации; Модуль «Смарт-клиент», представляющий собой ПО, устанавливаемое на средство отображения информации.',
        informaM6: 'Сервер управления средствами отображения',
        informaM7: 'Модуль «Сервер управления средствами отображения» реализует следующие функции:',
        informaM8: 'регистрацию средств отображения информации, на которых установлен модуль «Смарт-клиент»;',
        informaM9:
            'просмотр списка зарегистрированных средств отображения информации с возможностью сортировки и фильтрации;',
        informaM10: 'отображение состояния подключения средств отображения информации к серверу управления;',
        informaM11: 'поиск средств отображения информации;',
        informaM12: 'добавление, удаление, редактирование средств отображения информации;',
        informaM13: 'добавление, удаление, редактирование групп средств отображения информации;',
        informaM14: 'просмотр расширенной информации о средстве отображения информации;',
        informaM15:
            'загрузка и удаление контента через папку преподавателя для отображения на средстве отображения информации;',
        informaM16: 'возможность выбора папки для загрузки контента и управление структурой папок с контентом;',
        informaM17: 'формирование отчетов по работе средств отображения информации, воспроизводимому контенту;',
        informaM18: 'управление (предпросмотр в личном кабинете) воспроизведением контента на средствах отображения;',
        informaM19: 'поддержка личным кабинетом следующих языков отображения: казахский, русский, английский.',
        informaM20: 'В модуле предусмотрены следующие роли пользователей:',
        informaM21: 'Администратор подсистемы',
        informaM22: 'Администратор образовательных организаций (далее Администратор ОО)',
        informaM23: 'Педагогический работник',
        informaM24:
            'Администратору подсистемы и Администратору ОО доступны все перечисленные выше функциональные возможности. Администратору подсистемы  доступен весь перечень зарегистрированных средств отображения информации, а Администратору ОО  доступны лишь средства отображения информации, относящиеся к образовательной организации, которую он администрирует.',
        informaM25: 'Педагогическому работнику доступны следующие функциональные возможности:',
        informaM26:
            'загрузка и удаление контента через папку преподавателя для отображения на средстве отображения информации;',
        informaM27: 'возможность выбора папки для загрузки контента и управление структурой папок с контентом;',
        informaM28:
            'управление (предпросмотр в личном кабинете) воспроизведением контента на средстве отображения информации.',
        informaM29: 'Смарт-клиент',
        informaM30: 'Общие',
        informaM31:
            'Модуль «Смарт-клиент» предназначен для непосредственного управления воспроизведением контента на средствах отображения информации под управление операционных систем указанных в пункте 1.1 данного документа. Модуль «Смарт-клиент» должен быть реализован в виде приложения и работать на средствах отображения.',
        informaM32:
            'Установка модуля «Смарт-клиент» обеспечивает средствами, удобными для пользователя и традиционными для той платформы, для которой разработано приложение. Способы установки приложения включают в себя установку приложения из файла. После установки и запуска, модуль «Смарт-клиент» должен обеспечивать процесс регистрации средства отображения информации на «Сервер управления средствами отображения». После регистрации модуль «Смарт-клиент» выполняет следующие функции:',
        informaM33: 'первоначальную настройку и активацию средства отображения,',
        informaM34: 'авторизацию педагогического работника на средстве отображения информации,',
        informaM35:
            'отображение веб-страниц введенных в справочнике  «Сервера управления средствами отображения» в браузере «Смарт-клиент». Отображаемые веб страницы должны поддерживать веб элемент iFrame. Отображаемые Web страницы должны быть написаны с поддержкой версии веб движка M76 и M85,',
        informaM36: 'просмотр списка файлов,',
        informaM37: 'воспроизведение файлов,',
        informaM38: 'запуск официального приложения производителя аппаратного обеспечения из  «Смарт-клиент»,',
        informaM39: 'отображения входящего сигнала от HDMI источника.',
        informaM40: 'Первоначальная настройка и активация средства отображения',
        informaM41:
            'Модуль «Смарт-клиент» обеспечивает режим первоначальной настройки и активации. Этот режим включает при запуске средства отображения в случае, если не была произведена его активация. Модуль «Смарт-клиент» должен оставаться в режиме первоначальной настройки и активации до успешной активации средства отображения. В режиме первоначальной настройки модуль «Смарт-клиент» проверяет наличие соединения с модулем «Сервер управления средствами отображения». В случае отсутствия соединения модуль «Смарт-клиент» сообщает об этом пользователю. При успешном соединении с модулем «Сервер управления средствами отображения» на экран средства отображения выводится код активации. После активации средства отображения модуль «Смарт-клиент» выходит из режима первоначальной настройки и активации и обеспечивает доступ к другим функциям. До завершения процесса активации другие функции модуля не доступны пользователю.',
        informaM42: 'Отображение веб-страниц в браузере',
        informaM43:
            'Модуль «Смарт-клиент» обеспечивает возможность просмотра контента через обозреватель Интернет, установленный на средстве отображения информации (зависит от модели и ОС устройства) не требующий установки дополнительного программного обеспечения. Обозреватель обеспечивает просмотр веб-страниц, исполнение скриптов JavaScript.',
        informaM44: 'Просмотр списка файлов',
        informaM45:
            'Модуль «Смарт-клиент» обеспечивает возможность доступа к файлам и папкам в модуле «Сервер управления средствами отображения» для навигации по папкам и файлам, а также для воспроизведения файлов поддерживаемого формата. Модуль «Смарт-клиент» должен обеспечивать навигацию по файлам и папкам внешнего USB-накопителя (если функция поддерживается устройством). Модуль «Смарт-клиент» обеспечивает воспроизведение выбранного файла, если формат файла поддерживается. При навигации от пользователя должны скрываться документы, воспроизведение которых невозможно средствами модуля.',
        informaM46: 'Воспроизведение файлов',
        informaM47:
            'Функция воспроизведения файлов обеспечивает воспроизведение файлов, имеющих следующие форматы: файлы изображений в формате jpeg, png; видеофайлы в формате mp4; аудиофайлы в формате mp3; HTML файлы и веб-страницы, согласно пункту 1.2.2.1 подпункт 3. При воспроизведении аудио - и видеофайлов должна быть обеспечена возможность: ставить на паузу, возобновлять воспроизведение, перематывать вперед и назад.',
        feedbackDescription:
            'Вы получили это письмо, так как вы замечательный человек! Если письмо выглядит некорректно, откройте веб-версию письма. Вы считаете, что получили письмо по ошибке, или в нем содержатся ошибки, пожалуйста напишите нам об этом. '
    },
    en: {
        supportForNewProject: 'Contact us for cooperation and launching new projects',
        companyName: 'Company name',
        companySite: 'The site of the company',
        priceListTariffs: 'Price list and tariffs',
        supportForExistedProject: 'Contact us for technical support of completed projects',
        supportAboutProducts: 'By this phone you can request the cost of the software',
        mainDescription:
            'Unified platform for multimedia projects of any complexity and centralized device management at points of sale.',
        'menu.aboutUs': 'About Us',
        'menu.prices': 'Pricing',
        'menu.contacts': 'Contact',
        'menu.documentation': 'Documentation',
        try: 'Try For Free',
        tryForFree: 'Try For Free',
        theyTrustUs: 'Our Clients',
        solutions: 'Our Solutions',
        'solutions.slide1.title': 'VideoWall',
        'solutions.slide1.text1': 'Effective visitor communication channel',
        'solutions.slide1.text2':
            'Integration with cash systems to automatically update prices and product availability',
        'solutions.slide1.text3':
            'Content synchronization without additional controllers. Helps to draw additional attention to the action on the MenuBoard',
        'solutions.slide2.title': 'Video Surveillance',
        'solutions.slide2.text1': 'Combining all cameras in one reliable system',
        'solutions.slide2.text2': 'Customer Service Improvement',
        'solutions.slide2.text3': 'Security',
        'solutions.slide2.text4': 'Estimated store traffic',
        'solutions.slide2.text5': 'Cash Zone Control',
        'solutions.slide2.text6': 'Screen advertising targeting',
        'solutions.slide2.text7': 'Create an audience portrait',
        'solutions.slide3.title': 'Touch Panel',
        'solutions.slide3.text1':
            'Use omni channel mechanics. Integration with e-commerce systems. Order goods that are not available from another store or warehouse.',
        'solutions.slide3.text2':
            'Engage users in independent study of the product, do up-sell, cross-sell due to the possibility of viewing more expensive versions of the product and showing recommendations.',
        'solutions.slide4.title': 'Checkout Screen',
        'solutions.slide4.text1':
            'Additional monetization through integration with programmatic platforms Yandex / Mail.ru / Between Exchange',
        'solutions.slide4.text2': 'Social services, for example, an application for sign language translation',
        'solutions.slide4.text3': 'Loyalty Analysis / Service Quality Assessment',
        'solutions.slide4.text4':
            'The ability to display specials. Offers / stocks. Sales growth for displayed products up to 5%',
        'solutions.slide4.text5':
            'The ability to display the check / amount (integration with the cash system) on the screen to further attract attention to the screen',
        'solutions.slide4.text6':
            'Increase in the average check by showing personalized offers based on previous purchases and the current basket (integration with the loyalty system)',
        'solutions.slide5.title': 'Screens + Background Music',
        'solutions.slide5.text1':
            'Show videos and output additional independent background music from one screen! No additional device or license required.',
        'solutions.slide6.title': 'Showcase Displays',
        'solutions.slide6.text1':
            'Attract attention, increase traffic at the point of sale, increase brand awareness and recall',
        'solutions.slide6.text2':
            'Due to the increased brightness and the function of dynamic adjustment of the backlight, they can work effectively both in daylight and in the dark',
        whySmartplayer: 'Why SmartPlayer?',
        contacts: 'Contacts',
        toContactUs: 'Contact Us',
        name: 'name',
        email: 'email',
        phone: 'Phone',
        mail: 'Email',
        message: 'Enter your message here',
        send: 'Send',
        agreeText: 'I agree to the processing of personal data',
        messageIsSent: 'Processing...',
        messageSendError: 'Message not sent. Email us directly:',
        messageSent: 'Message sent',
        contactsForCommunication: 'Moscow Office',
        address: 'Address',
        smartplayerOffice: '115114 MOSCOW, RUSSIAN FEDERATION <br /> 20-26 DERBENEVSKAYA ST., office 5',
        schedule: 'Schedule',
        'mon.fri': 'mon-fri',
        'sat.sun': 'sat-sun',
        dayOff: 'Day off',
        videowall: 'Videowall',
        music: 'Music',
        CCTV: 'CCTV',
        menuBoards: 'MenuBoard',
        touchPanels: 'Touch-panel',
        checkoutScreens: 'Customer Screen',
        displayDisplays: 'Showcase Displays',
        // Prices
        'prices.monthly': 'Monthly',
        'prices.early': 'Early',
        'prices.subscription': 'Subscription',
        'prices.forOneDevice': 'per 1 device',
        'prices.smartSoundDesc': 'Play audio and control background sounds',
        'prices.smartScreenDesc': 'Management of complex visual content on a large number of screens',
        'prices.smartTouchDesc': 'Manage interactive content, create and edit multimedia broadcasts',
        'prices.smartVideoWallDesc': 'Managing visual content on a video wall',
        'prices.smartVideoAnalyticsDesc': 'Video analysis and management of complex visual content',
        'prices.smartVideoAnalyticsAndTouchDesc':
            'Video analysis and management of complex visual and interactive content',
        'prices.chooseAService': 'Choose a service',
        devices: 'Devices',
        login: 'Login',
        demoModalDescription: `I want to <span style="color: ${themeConfig.colors.blue}">see the platform</span> in details`,
        requestADemo: 'Request a demo',
        demostrationDescription: 'Our experts will hold a demonstration of SmartPlayer in our best traditions',
        'demo.description1': 'in detail and in human language',
        'demo.description2': ' tailored to your specific tasks with examples',
        'demo.description3': 'with answers to any questions',
        'whatASmartplayer.title': 'What is Digital Signage and why you need SmartPlayer?',
        'whatASmartplayer.p1': `<span style="color: ${themeConfig.colors.blue}">Digital Signage</span> is a technology for providing the necessary information through digital display devices installed in public places.`,
        'whatASmartplayer.p2':
            'Digital display devices include screens, menuboards, LED screens, interactive kiosks, video walls, tablets, audio equipment, etc.',
        'whatASmartplayer.p3':
            'At the moment, this technology is actively developing and is effectively used in many industries. It replaces the current advertising display format - static POS materials, lightboxes',
        'whatASmartplayer.descriptionTitle': 'Popular solutions',
        'whatASmartplayer.listItem1': 'Broadcast Your Advertising Content',
        'whatASmartplayer.listItem2': 'Scheduled Audio Streaming',
        'whatASmartplayer.listItem3': 'Display the value of your products or promotional offers',
        'whatASmartplayer.listItem4': 'Self-service kiosks or navigation at the Malls',
        'whatASmartplayer.listItem5': 'Currency Exchange Board or Flight Schedule',
        'whatASmartplayer.bigDescription': `The platform <span style="color: ${themeConfig.colors.blue}">SmartPlayer</span> is a universal software for implementing these solutions. With the help of our software you can quickly publish the necessary content from anywhere in the world and monitor the performance of the equipment.`,

        // Табы
        'tabs.title': 'How SmartPlayer works?',

        // Создание
        'tabs.create.title': 'Upload the necessary content to the platform and edit it',
        'tabs.create.listTitle': 'Opportunities:',
        'tabs.create.listItems': 'Multi-zone<br/>Tickers<br/>RSS feed<br/>HTML<br/>Displaying web-frame pages',

        // Управление
        'tabs.control.title': 'Add content to the schedule',
        'tabs.control.listTitle': 'Opportunities:',
        'tabs.control.listItems': 'Create broadcasts<br/>Scheduled content<br/>Group devices by broadcast locations',

        // Проигрывание
        'tabs.play.title': 'Install licenses and download campaings to devices',
        'tabs.play.listTitle': 'Opportunities:',
        'tabs.play.listSubTitle': 'Management in 2 options:',
        'tabs.play.listItem1': 'Cloud SaaS<br/>Native Server',
        'tabs.play.listItem2': 'Cross-platform<br/>Auto Update',

        // Аналитика
        'tabs.analytic.title': 'Make permissions and monitor monitoring and analytics',
        'tabs.analytic.listTitle': 'Opportunities:',
        'tabs.analytic.listItems':
            'Differentiation by roles<br/>Differentiation by content zones<br/>Differentiation by device and broadcasting place',

        watchAVideo: 'Watch a video',
        feedbackButtonText: 'Write to us',
        feedbackTitle: 'Thanks for your feedback!',
        informationManagement: 'Educational content display and management system',
        modules: 'Modules',
        informaM1: 'Educational content display and management system',
        informaM2: 'Subsystem Composition',
        informaM3:
            'Educational content display and management system provides playback of multimedia content on devices (hereinafter referred to as the information display means). The means of displaying information can be various kinds of devices running under operating systems Tizen OS version 6.0 and higher, Android OS version 5.0 and higher.',
        informaM4: 'The subsystem consists of the following modules:',
        informaM5:
            'The "Display Control Server" module is designed for registration and centralized management of the "Smart Client" modules installed on the display media; The "Smart Client" module, which is software installed on the information display tool.',
        informaM6: 'Display Control Server',
        informaM7: 'The Display Tools Control Server module implements the following functions:',
        informaM8: 'registration of information display means on which the Smart Client module is installed;',
        informaM9:
            'viewing the list of registered means of displaying information with the possibility of sorting and filtering;',
        informaM10: 'displaying the connection status of information display means to the management server;',
        informaM11: 'search for means of displaying information;',
        informaM12: 'adding, deleting, editing means of displaying information;',
        informaM13: 'adding, deleting, editing groups of means of displaying information;',
        informaM14: 'viewing extended information about the means of displaying information;',
        informaM15:
            'loading and deleting content through the teachers folder for display on the information display tool;',
        informaM16:
            'the ability to select a folder for downloading content and manage the structure of folders with content;',
        informaM17: 'generation of reports on the operation of information display facilities, reproduced content;',
        informaM18: 'management (preview in your personal account) of content playback on display devices;',
        informaM19: 'personal account support for the following display languages: Kazakh, Russian, English.',
        informaM20: 'The module provides the following user roles:',
        informaM21: 'Subsystem Administrator',
        informaM22: 'Administrator of educational organizations (hereinafter PA Administrator)',
        informaM23: 'Teaching worker',
        informaM24:
            'The Subsystem Administrator and the TOE Administrator have access to all of the functionality listed above. The subsystem administrator has access to the entire list of registered information display tools, and the OO Administrator has access to only the information display tools related to the educational organization that he administers.',
        informaM25: 'The following functionality is available to the teacher:',
        informaM26:
            'loading and deleting content through the teachers folder for display on the information display tool;',
        informaM27:
            'the ability to select a folder for downloading content and manage the structure of folders with content;',
        informaM28:
            'management (preview in your personal account) of content playback on the information display tool.',
        informaM29: 'Smart client',
        informaM30: 'Are common',
        informaM31:
            'The "Smart Client" module is designed to directly control the playback of content on information display devices under the control of operating systems specified in clause 1.1 of this document. The "Smart Client" module must be implemented as an application and work on display tools.',
        informaM32:
            'Installing the Smart Client module provides user-friendly and traditional tools for the platform for which the application was developed. Application installation methods include installing the application from a file. After installation and launch, the "Smart Client" module must ensure the process of registering the information display tool on the "Display Tool Control Server". After registration, the Smart Client module performs the following functions:',
        informaM33: 'initial setup and activation of the display tool,',
        informaM34: 'authorization of a pedagogical worker on the means of displaying information,',
        informaM35:
            'displaying web pages entered in the "Display Control Server" directory in the "Smart Client" browser. The rendered web pages must support the iFrame web element. The displayed Web pages must be written with support for the M76 and M85 versions of the web engine,',
        informaM36: 'view the list of files,',
        informaM37: 'file playback,',
        informaM38: 'launching the official application of the hardware manufacturer from "Smart Client",',
        informaM39: 'display the incoming signal from the HDMI source.',
        informaM40: 'Initial setup and activation of the renderer',
        informaM41:
            'The "Smart Client" module provides the mode of initial setup and activation. This mode turns on at the start of the display tool if it has not been activated. The Smart Client module must remain in the initial setup and activation mode until the displayer is successfully activated. In the initial setup mode, the "Smart Client" module checks for a connection with the "Display Control Server" module. If there is no connection, the Smart Client module informs the user about it. Upon successful connection to the "Display Control Server" module, an activation code is displayed on the screen of the display tool. After activating the display tool, the Smart Client module exits the initial setup and activation mode and provides access to other functions. Until the activation process is completed, other functions of the module are not available to the user.',
        informaM42: 'Displaying web pages in a browser',
        informaM43:
            'The "Smart Client" module provides the ability to view content through an Internet browser installed on the information display device (depending on the model and OS of the device) that does not require the installation of additional software. The browser provides web browsing, execution of JavaScript scripts.',
        informaM44: 'Viewing a list of files',
        informaM45:
            'The "Smart Client" module provides the ability to access files and folders in the "Display Control Server" module to navigate through folders and files, as well as to play files of a supported format. The "Smart Client" module must provide navigation through files and folders of an external USB drive (if the function is supported by the device). The Smart Client module provides playback of the selected file if the file format is supported. When navigating, documents that cannot be reproduced by means of the module should be hidden from the user.',
        informaM46: 'File playback',
        informaM47:
            'The file playback function allows you to play files that have the following formats: jpeg, png image files; video files in mp4 format; audio files in mp3 format; HTML files and web pages, in accordance with paragraph 1.2.2.1 subparagraph 3. When playing audio and video files, it should be possible to: pause, resume playback, rewind and rewind.',
        feedbackDescription:
            'You received this letter because you are a wonderful person! If the letter looks incorrect, open the web version of the letter. You think that you received the letter by mistake, or it contains errors, please write to us about it. '
    },
    br: {
        supportForNewProject: '',
        companyName: 'Nome da empresa',
        companySite: 'O site da empresa',
        priceListTariffs: 'Tabela de preços e tarifas',
        supportForExistedProject: '',
        supportAboutProducts: 'Por este telefone você pode solicitar o custo do software',
        mainDescription:
            'Plataforma unificada para projetos multimídia de qualquer complexidade e gerenciamento centralizado de dispositivos em lojas de varejo',
        'menu.aboutUs': 'Sobre Nós',
        'menu.prices': 'Preço',
        'menu.contacts': 'Contato',
        'menu.documentation': 'Documentação',
        try: 'Experimente sem custo',
        tryForFree: 'Experimente sem custo',
        theyTrustUs: 'Nossos clientes',
        solutions: 'Nossas Soluções',
        'solutions.slide1.title': 'VideoWall',
        'solutions.slide1.text1': 'Effective visitor communication channel',
        'solutions.slide1.text2':
            'Integration with cash systems to automatically update prices and product availability',
        'solutions.slide1.text3':
            'Content synchronization without additional controllers. Helps to draw additional attention to the action on the MenuBoard',
        'solutions.slide2.title': 'Video Surveillance',
        'solutions.slide2.text1': 'Combining all cameras in one reliable system',
        'solutions.slide2.text2': 'Customer Service Improvement',
        'solutions.slide2.text3': 'Security',
        'solutions.slide2.text4': 'Estimated store traffic',
        'solutions.slide2.text5': 'Cash Zone Control',
        'solutions.slide2.text6': 'Screen advertising targeting',
        'solutions.slide2.text7': 'Create an audience portrait',
        'solutions.slide3.title': 'Touch Panel',
        'solutions.slide3.text1':
            'Use omni channel mechanics. Integration with e-commerce systems. Order goods that are not available from another store or warehouse.',
        'solutions.slide3.text2':
            'Engage users in independent study of the product, do up-sell, cross-sell due to the possibility of viewing more expensive versions of the product and showing recommendations.',
        'solutions.slide4.title': 'Checkout Screen',
        'solutions.slide4.text1':
            'Additional monetization through integration with programmatic platforms Yandex / Mail.ru / Between Exchange',
        'solutions.slide4.text2': 'Social services, for example, an application for sign language translation',
        'solutions.slide4.text3': 'Loyalty Analysis / Service Quality Assessment',
        'solutions.slide4.text4':
            'The ability to display specials. Offers / stocks. Sales growth for displayed products up to 5%',
        'solutions.slide4.text5':
            'The ability to display the check / amount (integration with the cash system) on the screen to further attract attention to the screen',
        'solutions.slide4.text6':
            'Increase in the average check by showing personalized offers based on previous purchases and the current basket (integration with the loyalty system)',
        'solutions.slide5.title': 'Screens + Background Music',
        'solutions.slide5.text1':
            'Show videos and output additional independent background music from one screen! No additional device or license required.',
        'solutions.slide6.title': 'Showcase Displays',
        'solutions.slide6.text1':
            'Attract attention, increase traffic at the point of sale, increase brand awareness and recall',
        'solutions.slide6.text2':
            'Due to the increased brightness and the function of dynamic adjustment of the backlight, they can work effectively both in daylight and in the dark',
        whySmartplayer: 'Por que SmartPlayer?',
        contacts: 'Contatos',
        toContactUs: 'Entre em contato conosco',
        name: 'Nome',
        email: 'e-mail',
        phone: 'Telefone',
        mail: 'Email',
        message: 'Digite sua mensagem aqui',
        send: 'Enviar',
        agreeText: 'Eu concordo com o gerenciamento dos meus dados pessoais',
        messageIsSent: 'Processando...',
        messageSendError: 'Oops... Parece que tivemos algum problema para enviar sua mensagem...',
        messageSent: 'Menssagem enviada!',
        contactsForCommunication: 'Escritório em Moscou',
        address: 'Endereço',
        smartplayerOffice: '115114 MOSCOU, FEDERAÇÃO RUSSA <br /> 20-26 DERBENEVSKAYA ST., Escritório 5',
        schedule: 'Atendimento:',
        'mon.fri': 'Segunda-Sexta',
        'sat.sun': 'Sábado-Domingo',
        dayOff: 'Fechado',
        videowall: 'Videowall',
        music: 'Musica',
        CCTV: 'CFTV',
        menuBoards: 'Menu',
        touchPanels: 'Touch-panel',
        checkoutScreens: 'Tela do cliente',
        displayDisplays: 'Showcase Displays',
        // Preços
        'prices.monthly': 'Mensal',
        'prices.early': 'Anual',
        'prices.subscription': 'Assinatura',
        'prices.forOneDevice': 'Preço por dispositivo',
        'prices.smartSoundDesc': 'Reproduzir áudio e controlar música de fundo',
        'prices.smartScreenDesc': 'Gerenciamento de conteúdo visual complexo em um grande número de telas',
        'prices.smartTouchDesc': 'Gerencie conteúdo interativo, crie e edite transmissões multimídia',
        'prices.smartVideoWallDesc': 'Gerenciando conteúdo visual em um video wall',
        'prices.smartVideoAnalyticsDesc': 'Análise de vídeo e gerenciamento de conteúdo visual complexo',
        'prices.smartVideoAnalyticsAndTouchDesc':
            'Análise de vídeo e gerenciamento de conteúdo visual interativo complexo',
        'prices.chooseAService': 'Escolha um serviço',
        devices: 'Dispositivos',
        login: 'Login',
        demoModalDescription: `I want to <span style="color: ${themeConfig.colors.blue}">see the platform</span> in details`,
        requestADemo: 'Solicitar uma demonstração',
        demostrationDescription:
            'Nossos especialistas farão uma demonstração do SmartPlayer em nossas melhores tradições',
        'demo.description1': 'em detalhes e na linguagem humana',
        'demo.description2': ' adaptado às suas tarefas específicas com exemplos',
        'demo.description3': 'com respostas a qualquer pergunta',
        'whatASmartplayer.title': 'O que é Digital Signage e por que você precisa do SmartPlayer?',
        'whatASmartplayer.p1': `<span style="color: ${themeConfig.colors.blue}">Digital Signage</span> é uma tecnologia para fornecer as informações necessárias por meio de dispositivos de exibição digital instalados em locais públicos.`,
        'whatASmartplayer.p2':
            'Os dispositivos de exibição digital incluem telas, menuboards, telas de LED, quiosques interativos, video walls, tablets, equipamentos de áudio, etc.',
        'whatASmartplayer.p3':
            'No momento, essa tecnologia está se desenvolvendo ativamente e é efetivamente usada em muitos setores. Ele substitui o formato atual de exibição de publicidade - materiais estáticos de PDV e lightboxes',
        'whatASmartplayer.descriptionTitle': 'Soluções populares',
        'whatASmartplayer.listItem1': 'Transmita seu conteúdo publicitário',
        'whatASmartplayer.listItem2': 'Streaming de áudio agendado',
        'whatASmartplayer.listItem3': 'Exiba o valor de seus produtos ou ofertas promocionais',
        'whatASmartplayer.listItem4': 'Quiosques de autoatendimento ou navegação nos shoppings',
        'whatASmartplayer.listItem5': 'Câmbio atual ou Horário de Voo',
        'whatASmartplayer.bigDescription': `The platform <span style="color: ${themeConfig.colors.blue}">SmartPlayer</span> é um software universal para implementar essas soluções. Com a ajuda do nosso software, você pode publicar rapidamente o conteúdo necessário de qualquer lugar do mundo e monitorar o desempenho do equipamento.`,
        // Табы
        'tabs.title': 'Como o SmartPlayer funciona?',
        // Создание
        'tabs.create.title': 'Carregue o conteúdo necessário para a plataforma e edite-o.',
        'tabs.create.listTitle': 'Oportunidades:',
        'tabs.create.listItems': 'Multi-zone<br/>Tickers<br/>RSS feed<br/>HTML<br/>Displaying web-frame pages',
        // Управление
        'tabs.control.title': 'Adicionar conteúdo à agenda',
        'tabs.control.listTitle': 'Oportunidades:',
        'tabs.control.listItems': 'Create broadcasts<br/>Scheduled content<br/>Group devices by broadcast locations',
        // Проигрывание
        'tabs.play.title': 'Instale licenças e baixe campanhas para dispositivos',
        'tabs.play.listTitle': 'Oportunidades:',
        'tabs.play.listSubTitle': 'Gerenciamento em 2 opções:',
        'tabs.play.listItem1': 'Cloud SaaS<br/>Native Server',
        'tabs.play.listItem2': 'Cross-platform<br/>Auto Update',
        // Аналитика
        'tabs.analytic.title': 'Faça permissões, monitore e acompanhe as análises',
        'tabs.analytic.listTitle': 'Oportunidades:',
        'tabs.analytic.listItems':
            'Differentiation by roles<br/>Diferenciação por zonas de conteúdo<br/>Diferenciação por dispositivo e local de transmissão',
        watchAVideo: 'Assita o vídeo',
        feedbackButtonText: 'Write to us',
        feedbackTitle: 'Thanks for your feedback!',
        informationManagement: 'Sistema de exibição e gerenciamento de conteúdo educacional',
        modules: 'Módulos',
        informaM1: 'Sistema de exibição e gerenciamento de conteúdo educacional',
        informaM2: 'Composição do Subsistema',
        informaM3:
            'O sistema de exibição e gerenciamento de conteúdo educacional fornece reprodução de conteúdo multimídia em dispositivos (doravante denominados meios de exibição de informações). Os meios de exibição de informações podem ser vários tipos de dispositivos em execução nos sistemas operacionais Tizen OS versão 6.0 e superior, Android OS versão 5.0 e superior.',
        informaM4: 'O subsistema é composto pelos seguintes módulos:',
        informaM5:
            'O módulo "Display Control Server" é projetado para registro e gerenciamento centralizado dos módulos "Smart Client" instalados na mídia de exibição; O módulo "Smart Client", que é um software instalado na ferramenta de exibição de informações.',
        informaM6: 'Exibir servidor de controle',
        informaM7: 'O módulo Display Tools Control Server implementa as seguintes funções:',
        informaM8: 'registro dos meios de exibição de informações nos quais o módulo Smart Client está instalado;',
        informaM9:
            'visualização da lista de meios cadastrados de exibição de informações com possibilidade de ordenação e filtragem;',
        informaM10: 'exibir o status da conexão dos meios de exibição de informações para o servidor de gerenciamento;',
        informaM11: 'busca por meios de exibição de informações;',
        informaM12: 'adicionar, excluir, editar meios de exibição de informações;',
        informaM13: 'adicionar, excluir, editar grupos de meios de exibição de informações;',
        informaM14: 'visualização de informações estendidas sobre os meios de exibição de informações;',
        informaM15:
            'carregamento e exclusão de conteúdo por meio da pasta do professor para exibição na ferramenta de exibição de informações;',
        informaM16:
            'a capacidade de selecionar uma pasta para baixar conteúdo e gerenciar a estrutura de pastas com conteúdo;',
        informaM17:
            'geração de relatórios sobre a operação de instalações de exibição de informações, conteúdo reproduzido;',
        informaM18:
            'gerenciamento (visualização em sua conta pessoal) de reprodução de conteúdo em dispositivos de exibição;',
        informaM19: 'suporte de conta pessoal para os seguintes idiomas de exibição: cazaque, russo, inglês.',
        informaM20: 'O módulo fornece as seguintes funções de usuário:',
        informaM21: 'Administrador do Subsistema',
        informaM22: 'Administrador de organizações educacionais (doravante Administrador de PA)',
        informaM23: 'Trabalhador de ensino',
        informaM24:
            'O Administrador do Subsistema e o Administrador do TOE têm acesso a todas as funcionalidades listadas acima. O administrador do subsistema tem acesso a toda a lista de ferramentas de exibição de informações cadastradas, e o Administrador OO tem acesso apenas às ferramentas de exibição de informações relacionadas à organização educacional que administra.',
        informaM25: 'A seguinte funcionalidade está disponível para o professor:',
        informaM26:
            'carregamento e exclusão de conteúdo por meio da pasta do professor para exibição na ferramenta de exibição de informações;',
        informaM27:
            'a capacidade de selecionar uma pasta para baixar conteúdo e gerenciar a estrutura de pastas com conteúdo;',
        informaM28:
            'gerenciamento (visualização em sua conta pessoal) de reprodução de conteúdo na ferramenta de exibição de informações.',
        informaM29: 'Cliente inteligente',
        informaM30: 'São comuns',
        informaM31:
            'O módulo "Smart Client" é projetado para controlar diretamente a reprodução de conteúdo em dispositivos de exibição de informações sob o controle de sistemas operacionais especificados na cláusula 1.1 deste documento. O módulo "Smart Client" deve ser implementado como um aplicativo e funcionar em ferramentas de exibição.',
        informaM32:
            'A instalação do módulo Smart Client fornece ferramentas amigáveis ​​e tradicionais para a plataforma para a qual o aplicativo foi desenvolvido. Os métodos de instalação do aplicativo incluem a instalação do aplicativo a partir de um arquivo. Após a instalação e inicialização, o módulo "Smart Client" deve garantir o processo de registro da ferramenta de exibição de informações no "Servidor de controle da ferramenta de exibição". Após o registro, o módulo Smart Client executa as seguintes funções:',
        informaM33: 'configuração inicial e ativação da ferramenta de exibição,',
        informaM34: 'autorização de um trabalhador pedagógico sobre os meios de exibição de informações,',
        informaM35:
            'exibir páginas da web inseridas no diretório "Display Control Server" no navegador "Smart Client". As páginas da web renderizadas devem suportar o elemento da web iFrame. As páginas da Web exibidas devem ser escritas com suporte para as versões M76 e M85 do mecanismo da web,',
        informaM36: 'ver a lista de arquivos,',
        informaM37: 'reprodução de arquivo,',
        informaM38: 'lançando o aplicativo oficial do fabricante de hardware de "Smart Client",',
        informaM39: 'exibir o sinal de entrada da fonte HDMI.',
        informaM40: 'Configuração inicial e ativação do renderizador',
        informaM41:
            'O módulo "Smart Client" fornece o modo de configuração inicial e ativação. Este modo é ativado no início da ferramenta de exibição, caso não tenha sido ativado. O módulo Smart Client deve permanecer na configuração inicial e no modo de ativação até que o visualizador seja ativado com sucesso. No modo de configuração inicial, o módulo "Smart Client" verifica a conexão com o módulo "Display Control Server". Se não houver conexão, o módulo Smart Client informa o usuário sobre isso. Após a conexão bem-sucedida ao módulo "Display Control Server", um código de ativação é exibido na tela da ferramenta de exibição. Depois de ativar a ferramenta de exibição, o módulo Smart Client sai da configuração inicial e do modo de ativação e fornece acesso a outras funções. Até que o processo de ativação seja concluído, outras funções do módulo não estão disponíveis para o usuário.',
        informaM42: 'Exibição de páginas da Web em um navegador',
        informaM43:
            'O módulo "Smart Client" oferece a capacidade de visualizar o conteúdo por meio de um navegador da Internet instalado no dispositivo de exibição de informações (dependendo do modelo e sistema operacional do dispositivo) que não requer a instalação de software adicional. O navegador fornece navegação na web, execução de scripts JavaScript.',
        informaM44: 'Visualizando uma lista de arquivos',
        informaM45:
            'O módulo "Smart Client" fornece a capacidade de acessar arquivos e pastas no módulo "Display Control Server" para navegar por pastas e arquivos, bem como reproduzir arquivos de um formato suportado. O módulo "Smart Client" deve fornecer navegação por arquivos e pastas de uma unidade USB externa (se a função for suportada pelo dispositivo). O módulo Smart Client fornece a reprodução do arquivo selecionado se o formato do arquivo for suportado. Durante a navegação, os documentos que não podem ser reproduzidos por meio do módulo devem ser ocultados do usuário.',
        informaM46: 'Reprodução de arquivo',
        informaM47:
            'A função de reprodução de arquivo permite reproduzir arquivos com os seguintes formatos: jpeg, arquivos de imagem png; arquivos de vídeo em formato mp4; arquivos de áudio em formato mp3; Arquivos HTML e páginas da web, de acordo com o parágrafo 1.2.2.1, parágrafo 3. Ao reproduzir arquivos de áudio e vídeo, deve ser possível: pausar, retomar a reprodução, retroceder e retroceder.',
        feedbackDescription:
            'You received this letter because you are a wonderful person! If the letter looks incorrect, open the web version of the letter. You think that you received the letter by mistake, or it contains errors, please write to us about it. '
    },
    pt: {
        supportForNewProject: 'Contacte-nos para cooperação e lançamento de novos projetos',
        companyName: 'Nome da empresa',
        companySite: 'O site da empresa',
        priceListTariffs: 'Tabela de preços e tarifas',
        supportForExistedProject: 'Entre em contato conosco para suporte técnico de projetos concluídos',
        supportAboutProducts: 'Por este telefone você pode solicitar o custo do software',
        mainDescription:
            'Plataforma unificada para projetos multimídia de qualquer complexidade e gerenciamento centralizado de dispositivos nos pontos de venda',
        'menu.aboutUs': 'Sobre Nós',
        'menu.prices': 'Preço',
        'menu.contacts': 'Contato',
        'menu.documentation': 'Documentação',
        try: 'Experimente sem custo',
        tryForFree: 'Experimente sem custo',
        theyTrustUs: 'Nossos clientes',
        solutions: 'Nossas Soluções',
        'solutions.slide1.title': 'VideoWall',
        'solutions.slide1.text1': 'Um canal eficaz para comunicar com os visitantes',
        'solutions.slide1.text2':
            'A integração com sistemas PDV permite atualização automatica de preços e disponibilidades de stock',
        'solutions.slide1.text3':
            'Sincronização de conteúdo sem necessidade de ferramentas adicionais. Efeito de Video Wall ajuda atrair mais atenção ao conteúdo de MenuBoard',
        'solutions.slide2.title': 'Vigilância por vídeo',
        'solutions.slide2.text1': 'Combinação de todas as câmeras em um sistema confiável',
        'solutions.slide2.text2': 'Melhoria da qualidade do atendimento ao cliente',
        'solutions.slide2.text3': 'Garantia de segurança dos pontos de venda',
        'solutions.slide2.text4': 'Avaliação de tráfego da loja',
        'solutions.slide2.text5': 'Controle de área de checkout',
        'solutions.slide2.text6': 'Segmentação de anúncios nas telas',
        'solutions.slide2.text7': 'Create an audience portrait',
        'solutions.slide3.title': 'Sistemas Touch',
        'solutions.slide3.text1':
            'Capacidade de usar a mecânica omnicanal por meio da integração com sistemas de e-commerce. Possibilidade de encomendar produtos em falta de outra loja ou de um armazém',
        'solutions.slide3.text2':
            'Envolvimento dos usuários no estudo independente do produto, possibilidade de fazer cross- e up-sell devido à capacidade de visualizar versões mais caras do produto e exibir recomendações',
        'solutions.slide4.title': 'Monitores para a zona da caixa',
        'solutions.slide4.text1':
            'Monitorização adicional através da integração com plataformas programáticas tipo Between Exchange',
        'solutions.slide4.text2':
            'Serviços sociais, como por exemplo a integração de serviços de tradução para linguagem gestual',
        'solutions.slide4.text3': 'Análise de lealdade / avaliação da qualidade do serviço',
        'solutions.slide4.text4':
            'Capacidade de mostrar ofertas especiais / promoções. Crescimento das vendas de produtos exibidos em até 5%',
        'solutions.slide4.text5':
            'Possibilidade de visualização do valor da compra (integração com o sistema da caixa registradora) no monitor para chamar ainda mais a atenção para o monitor',
        'solutions.slide4.text6':
            'Aumento do ticket médio com apresentação de ofertas personalizadas com base nas compras anteriores e na cesta atual (integração com o sistema de fidelização)',
        'solutions.slide5.title': 'Monitores + Música de fundo',
        'solutions.slide5.text1':
            'Possibilidade de reprodução de banda sonora adicional, independente da sequência de vídeo, desde o monitor. Nenhum dispositivo ou licença adicionais necessários.',
        'solutions.slide6.title': 'Vitrines Digitais',
        'solutions.slide6.text1':
            'Atraem a atenção, ajudam aumentar o tráfego no ponto de venda, tanto como a visibilidade da marca',
        'solutions.slide6.text2':
            'Devido ao nível alto do brilho e à função de ajuste dinâmico do mesmo, podem funcionar efetivamente tanto durante o dia quanto à noite',
        whySmartplayer: 'Por que SmartPlayer?',
        contacts: 'Contatos',
        toContactUs: 'Entre em contato conosco',
        name: 'Nome',
        email: 'e-mail',
        phone: 'Telefone',
        mail: 'Email',
        message: 'Digite sua mensagem aqui',
        send: 'Enviar',
        agreeText: 'Eu concordo com o gerenciamento dos meus dados pessoais',
        messageIsSent: 'Processando...',
        messageSendError: 'Mensagem não enviada. Envie-nos um e-mail diretamente:',
        messageSent: 'Menssagem enviada!',
        contactsForCommunication: 'Escritório em Moscou',
        address: 'Endereço',
        smartplayerOffice: '115114 MOSCOU, FEDERAÇÃO RUSSA <br /> 20-26 DERBENEVSKAYA ST., Escritório 5',
        schedule: 'Atendimento:',
        'mon.fri': 'Segunda-Sexta',
        'sat.sun': 'Sábado-Domingo',
        dayOff: 'Fechado',
        videowall: 'Videowall',
        music: 'Musica',
        CCTV: 'CFTV',
        menuBoards: 'Menu',
        touchPanels: 'Touch-panel',
        checkoutScreens: 'Tela do cliente',
        displayDisplays: 'Showcase Displays',
        // Preços
        'prices.monthly': 'Mensal',
        'prices.early': 'Anual',
        'prices.subscription': 'Assinatura',
        'prices.forOneDevice': 'Preço por dispositivo',
        'prices.smartSoundDesc': 'Reproduzir áudio e controlar música de fundo',
        'prices.smartScreenDesc': 'Gerenciamento de conteúdo visual complexo em um grande número de telas',
        'prices.smartTouchDesc': 'Gerencie conteúdo interativo, crie e edite transmissões multimídia',
        'prices.smartVideoWallDesc': 'Gerenciando conteúdo visual em um video wall',
        'prices.smartVideoAnalyticsDesc': 'Análise de vídeo e gerenciamento de conteúdo visual complexo',
        'prices.smartVideoAnalyticsAndTouchDesc':
            'Análise de vídeo e gerenciamento de conteúdo visual interativo complexo',
        'prices.chooseAService': 'Escolha um serviço',
        devices: 'Dispositivos',
        login: 'Login',
        demoModalDescription: `I want to <span style="color: ${themeConfig.colors.blue}">see the platform</span> in details`,
        requestADemo: 'Solicitar uma demonstração',
        demostrationDescription:
            'Nossos especialistas farão uma demonstração do SmartPlayer em nossas melhores tradições',
        'demo.description1': 'em detalhes e na linguagem humana',
        'demo.description2': ' adaptado às suas tarefas específicas com exemplos',
        'demo.description3': 'com respostas a qualquer pergunta',
        'whatASmartplayer.title': 'O que é Sinalização Digital e por que você precisa do SmartPlayer?',
        'whatASmartplayer.p1': `<span style="color: ${themeConfig.colors.blue}">Sinalização digital</span> é uma tecnologia que permite fornecer as informações por meio de dispositivos de exibição digital instalados em locais públicos.`,
        'whatASmartplayer.p2':
            'Os dispositivos de exibição digital incluem telas, menuboards, telas de LED, quiosques interativos, video walls, tablets, equipamentos de áudio, etc.',
        'whatASmartplayer.p3':
            'É uma tecnologia em fase de crescimento ativo que começa a ser usada em muitos setores, substituindo o formato tradicional de exibição de publicidade como materiais estáticos de PDV e lightboxes.',
        'whatASmartplayer.descriptionTitle': 'Aguns exemplos',
        'whatASmartplayer.listItem1': 'Transmita seu conteúdo publicitário',
        'whatASmartplayer.listItem2': 'Faça streaming agendando do seu áudio',
        'whatASmartplayer.listItem3': 'Exiba o valor dos seus produtos ou ofertas promocionais',
        'whatASmartplayer.listItem4': 'Quiosques de autoatendimento ou navegação nos shoppings',
        'whatASmartplayer.listItem5': 'Câmbio atual ou Horário de Voo',
        'whatASmartplayer.bigDescription': `A plataforma <span style="color: ${themeConfig.colors.blue}">SmartPlayer</span> é um software universal para implementação dessas soluções. Com o nosso software, você tanto pode rapidamente publicar o conteúdo estando em qualquer lugar do mundo como monitorar o desempenho dos seus equipamentos.`,
        // Табы
        'tabs.title': 'Como funciona o sistema SmartPlayer?',
        // Создание
        'tabs.create.title': 'Carregue o conteúdo e edite-o na própria plataforma.',
        'tabs.create.listTitle': 'Funcionalidades:',
        'tabs.create.listItems':
            'Multi-zone<br/>Tickers<br/>RSS feed<br/>HTML<br/>Possibilidade se exibição de\n páginas de web-frame',
        // Управление
        'tabs.control.title': 'Adicionar conteúdo à agenda',
        'tabs.control.listTitle': 'Oportunidades:',
        'tabs.control.listItems': 'Create broadcasts<br/>Scheduled content<br/>Group devices by broadcast locations',
        // Проигрывание
        'tabs.play.title': 'Instale licenças e baixe campanhas para dispositivos',
        'tabs.play.listTitle': 'Oportunidades:',
        'tabs.play.listSubTitle': 'Gerenciamento em 2 opções:',
        'tabs.play.listItem1': 'Cloud SaaS<br/>Native Server',
        'tabs.play.listItem2': 'Cross-platform<br/>Auto Update',
        // Аналитика
        'tabs.analytic.title': 'Faça permissões, monitore e acompanhe as análises',
        'tabs.analytic.listTitle': 'Oportunidades:',
        'tabs.analytic.listItems':
            'Differentiation by roles<br/>Diferenciação por zonas de conteúdo<br/>Diferenciação por dispositivo e local de transmissão',
        watchAVideo: 'Assita o vídeo',
        feedbackButtonText: 'Write to us',
        feedbackTitle: 'Thanks for your feedback!',
        informationManagement: 'Sistema de exibição e gerenciamento de conteúdo educacional',
        modules: 'Módulos',
        informaM1: 'Sistema de exibição e gerenciamento de conteúdo educacional',
        informaM2: 'Composição do Subsistema',
        informaM3:
            'O sistema de exibição e gerenciamento de conteúdo educacional fornece reprodução de conteúdo multimídia em dispositivos (doravante denominados meios de exibição de informações). Os meios de exibição de informações podem ser vários tipos de dispositivos em execução nos sistemas operacionais Tizen OS versão 6.0 e superior, Android OS versão 5.0 e superior.',
        informaM4: 'O subsistema é composto pelos seguintes módulos:',
        informaM5:
            'O módulo "Display Control Server" é projetado para registro e gerenciamento centralizado dos módulos "Smart Client" instalados na mídia de exibição; O módulo "Smart Client", que é um software instalado na ferramenta de exibição de informações.',
        informaM6: 'Exibir servidor de controle',
        informaM7: 'O módulo Display Tools Control Server implementa as seguintes funções:',
        informaM8: 'registro dos meios de exibição de informações nos quais o módulo Smart Client está instalado;',
        informaM9:
            'visualização da lista de meios cadastrados de exibição de informações com possibilidade de ordenação e filtragem;',
        informaM10: 'exibir o status da conexão dos meios de exibição de informações para o servidor de gerenciamento;',
        informaM11: 'busca por meios de exibição de informações;',
        informaM12: 'adicionar, excluir, editar meios de exibição de informações;',
        informaM13: 'adicionar, excluir, editar grupos de meios de exibição de informações;',
        informaM14: 'visualização de informações estendidas sobre os meios de exibição de informações;',
        informaM15:
            'carregamento e exclusão de conteúdo por meio da pasta do professor para exibição na ferramenta de exibição de informações;',
        informaM16:
            'a capacidade de selecionar uma pasta para baixar conteúdo e gerenciar a estrutura de pastas com conteúdo;',
        informaM17:
            'geração de relatórios sobre a operação de instalações de exibição de informações, conteúdo reproduzido;',
        informaM18:
            'gerenciamento (visualização em sua conta pessoal) de reprodução de conteúdo em dispositivos de exibição;',
        informaM19: 'suporte de conta pessoal para os seguintes idiomas de exibição: cazaque, russo, inglês.',
        informaM20: 'O módulo fornece as seguintes funções de usuário:',
        informaM21: 'Administrador do Subsistema',
        informaM22: 'Administrador de organizações educacionais (doravante Administrador de PA)',
        informaM23: 'Trabalhador de ensino',
        informaM24:
            'O Administrador do Subsistema e o Administrador do TOE têm acesso a todas as funcionalidades listadas acima. O administrador do subsistema tem acesso a toda a lista de ferramentas de exibição de informações cadastradas, e o Administrador OO tem acesso apenas às ferramentas de exibição de informações relacionadas à organização educacional que administra.',
        informaM25: 'A seguinte funcionalidade está disponível para o professor:',
        informaM26:
            'carregamento e exclusão de conteúdo por meio da pasta do professor para exibição na ferramenta de exibição de informações;',
        informaM27:
            'a capacidade de selecionar uma pasta para baixar conteúdo e gerenciar a estrutura de pastas com conteúdo;',
        informaM28:
            'gerenciamento (visualização em sua conta pessoal) de reprodução de conteúdo na ferramenta de exibição de informações.',
        informaM29: 'Cliente inteligente',
        informaM30: 'São comuns',
        informaM31:
            'O módulo "Smart Client" é projetado para controlar diretamente a reprodução de conteúdo em dispositivos de exibição de informações sob o controle de sistemas operacionais especificados na cláusula 1.1 deste documento. O módulo "Smart Client" deve ser implementado como um aplicativo e funcionar em ferramentas de exibição.',
        informaM32:
            'A instalação do módulo Smart Client fornece ferramentas amigáveis ​​e tradicionais para a plataforma para a qual o aplicativo foi desenvolvido. Os métodos de instalação do aplicativo incluem a instalação do aplicativo a partir de um arquivo. Após a instalação e inicialização, o módulo "Smart Client" deve garantir o processo de registro da ferramenta de exibição de informações no "Servidor de controle da ferramenta de exibição". Após o registro, o módulo Smart Client executa as seguintes funções:',
        informaM33: 'configuração inicial e ativação da ferramenta de exibição,',
        informaM34: 'autorização de um trabalhador pedagógico sobre os meios de exibição de informações,',
        informaM35:
            'exibir páginas da web inseridas no diretório "Display Control Server" no navegador "Smart Client". As páginas da web renderizadas devem suportar o elemento da web iFrame. As páginas da Web exibidas devem ser escritas com suporte para as versões M76 e M85 do mecanismo da web,',
        informaM36: 'ver a lista de arquivos,',
        informaM37: 'reprodução de arquivo,',
        informaM38: 'lançando o aplicativo oficial do fabricante de hardware de "Smart Client",',
        informaM39: 'exibir o sinal de entrada da fonte HDMI.',
        informaM40: 'Configuração inicial e ativação do renderizador',
        informaM41:
            'O módulo "Smart Client" fornece o modo de configuração inicial e ativação. Este modo é ativado no início da ferramenta de exibição, caso não tenha sido ativado. O módulo Smart Client deve permanecer na configuração inicial e no modo de ativação até que o visualizador seja ativado com sucesso. No modo de configuração inicial, o módulo "Smart Client" verifica a conexão com o módulo "Display Control Server". Se não houver conexão, o módulo Smart Client informa o usuário sobre isso. Após a conexão bem-sucedida ao módulo "Display Control Server", um código de ativação é exibido na tela da ferramenta de exibição. Depois de ativar a ferramenta de exibição, o módulo Smart Client sai da configuração inicial e do modo de ativação e fornece acesso a outras funções. Até que o processo de ativação seja concluído, outras funções do módulo não estão disponíveis para o usuário.',
        informaM42: 'Exibição de páginas da Web em um navegador',
        informaM43:
            'O módulo "Smart Client" oferece a capacidade de visualizar o conteúdo por meio de um navegador da Internet instalado no dispositivo de exibição de informações (dependendo do modelo e sistema operacional do dispositivo) que não requer a instalação de software adicional. O navegador fornece navegação na web, execução de scripts JavaScript.',
        informaM44: 'Visualizando uma lista de arquivos',
        informaM45:
            'O módulo "Smart Client" fornece a capacidade de acessar arquivos e pastas no módulo "Display Control Server" para navegar por pastas e arquivos, bem como reproduzir arquivos de um formato suportado. O módulo "Smart Client" deve fornecer navegação por arquivos e pastas de uma unidade USB externa (se a função for suportada pelo dispositivo). O módulo Smart Client fornece a reprodução do arquivo selecionado se o formato do arquivo for suportado. Durante a navegação, os documentos que não podem ser reproduzidos por meio do módulo devem ser ocultados do usuário.',
        informaM46: 'Reprodução de arquivo',
        informaM47:
            'A função de reprodução de arquivo permite reproduzir arquivos com os seguintes formatos: jpeg, arquivos de imagem png; arquivos de vídeo em formato mp4; arquivos de áudio em formato mp3; Arquivos HTML e páginas da web, de acordo com o parágrafo 1.2.2.1, parágrafo 3. Ao reproduzir arquivos de áudio e vídeo, deve ser possível: pausar, retomar a reprodução, retroceder e retroceder.',
        feedbackDescription:
            'You received this letter because you are a wonderful person! If the letter looks incorrect, open the web version of the letter. You think that you received the letter by mistake, or it contains errors, please write to us about it. '
    },
    es: {
        supportForNewProject: 'Contáctenos para cooperación y lanzamiento de nuevos proyectos.',
        companyName: 'Nombre de empresa',
        companySite: 'El sitio de la empresa',
        priceListTariffs: 'Lista de precios y tarifas',
        supportForExistedProject: 'Contáctenos para soporte técnico de proyectos terminados',
        supportAboutProducts: 'Por este teléfono puede solicitar el costo del software',
        mainDescription:
            'Plataforma unificada para proyectos multimedia de cualquier complejidad y gestión centralizada de dispositivos en puntos de venta.',
        'menu.aboutUs': 'Acerca de Nosotros',
        'menu.prices': 'Precios',
        'menu.contacts': 'Contacto',
        'menu.documentation': 'Documentación',
        try: 'Prueba Gratuita',
        tryForFree: 'Prueba Gratuita',
        theyTrustUs: 'Nuestros clientes',
        solutions: 'Nuestras Soluciones',
        'solutions.slide1.title': 'VideoWall',
        'solutions.slide1.text1': 'Effective visitor communication channel',
        'solutions.slide1.text2':
            'Integration with cash systems to automatically update prices and product availability',
        'solutions.slide1.text3':
            'Content synchronization without additional controllers. Helps to draw additional attention to the action on the MenuBoard',
        'solutions.slide2.title': 'Video Surveillance',
        'solutions.slide2.text1': 'Combining all cameras in one reliable system',
        'solutions.slide2.text2': 'Customer Service Improvement',
        'solutions.slide2.text3': 'Security',
        'solutions.slide2.text4': 'Estimated store traffic',
        'solutions.slide2.text5': 'Cash Zone Control',
        'solutions.slide2.text6': 'Screen advertising targeting',
        'solutions.slide2.text7': 'Create an audience portrait',
        'solutions.slide3.title': 'Touch Panel',
        'solutions.slide3.text1':
            'Use omni channel mechanics. Integration with e-commerce systems. Order goods that are not available from another store or warehouse.',
        'solutions.slide3.text2':
            'Engage users in independent study of the product, do up-sell, cross-sell due to the possibility of viewing more expensive versions of the product and showing recommendations.',
        'solutions.slide4.title': 'Checkout Screen',
        'solutions.slide4.text1':
            'Additional monetization through integration with programmatic platforms Yandex / Mail.ru / Between Exchange',
        'solutions.slide4.text2': 'Social services, for example, an application for sign language translation',
        'solutions.slide4.text3': 'Loyalty Analysis / Service Quality Assessment',
        'solutions.slide4.text4':
            'The ability to display specials. Offers / stocks. Sales growth for displayed products up to 5%',
        'solutions.slide4.text5':
            'The ability to display the check / amount (integration with the cash system) on the screen to further attract attention to the screen',
        'solutions.slide4.text6':
            'Increase in the average check by showing personalized offers based on previous purchases and the current basket (integration with the loyalty system)',
        'solutions.slide5.title': 'Screens + Background Music',
        'solutions.slide5.text1':
            'Show videos and output additional independent background music from one screen! No additional device or license required.',
        'solutions.slide6.title': 'Showcase Displays',
        'solutions.slide6.text1':
            'Attract attention, increase traffic at the point of sale, increase brand awareness and recall',
        'solutions.slide6.text2':
            'Due to the increased brightness and the function of dynamic adjustment of the backlight, they can work effectively both in daylight and in the dark',
        whySmartplayer: '¿Por qué SmartPlayer?',
        contacts: 'Contactos',
        toContactUs: 'Contáctanos',
        name: 'Nombre',
        email: 'Correo Electrónico',
        phone: 'Teléfono',
        mail: 'Correo electrónico',
        message: 'Ingrese Su Mensaje Aquí',
        send: 'Enviar',
        agreeText: 'Acepto el procesamiento de datos personales',
        messageIsSent: 'Procesando...',
        messageSendError: 'Mensaje no enviado. Envíenos un correo electrónico directamente:',
        messageSent: 'Mensaje enviado',
        contactsForCommunication: 'Oficina de Moscú',
        address: 'Dirección',
        smartplayerOffice: '115114 MOSCÚ, FEDERACIÓN DE RUSIA <br /> 20-26 DERBENEVSKAYA ST., Oficina 5',
        schedule: 'Horario',
        'mon.fri': 'lun-vie',
        'sat.sun': 'sab-dom',
        dayOff: 'Día Libre',
        videowall: 'Pared de video',
        music: 'Música',
        CCTV: 'CCTV',
        menuBoards: 'Menú',
        touchPanels: 'Panel táctil',
        checkoutScreens: 'Pantalla de cliente',
        displayDisplays: 'Escaparate de exhibición',
        // Preços
        'prices.monthly': 'Mensual',
        'prices.early': 'Adelantado',
        'prices.subscription': 'Suscripción',
        'prices.forOneDevice': 'Por 1 dispositivo',
        'prices.smartSoundDesc': 'Reproduce audio y controla los sonidos de fondo',
        'prices.smartScreenDesc': 'Gestión de contenido visual complejo en un gran número de pantallas',
        'prices.smartTouchDesc': 'Administrar contenido interactivo, crear y editar transmisiones multimedia',
        'prices.smartVideoWallDesc': 'Administrar contenido visual en un mural de videos',
        'prices.smartVideoAnalyticsDesc': 'Análisis de video y gestión de contenido visual complejo',
        'prices.smartVideoAnalyticsAndTouchDesc':
            'Análisis de vídeo y gestión de contenido visual e interactivo complejo',
        'prices.chooseAService': 'Elija un servicio',
        devices: 'Dispositivos',
        login: 'Iniciar sesión',
        demoModalDescription: `Quiero <span style="color: ${themeConfig.colors.blue}">ver la plataforma</span> en detalles`,
        requestADemo: 'Solicite una demostración',
        demostrationDescription:
            'Nuestros expertos realizarán una demostración de SmartPlayer en nuestras mejores tradiciones',
        'demo.description1': 'En detalle y en lenguaje humano',
        'demo.description2': 'Adaptado a tus tareas específicas con ejemplos',
        'demo.description3': 'Con respuestas a cualquier pregunta',
        'whatASmartplayer.title': '¿Qué es Digital Signage y por qué necesita SmartPlayer?',
        'whatASmartplayer.p1': `<span style="color: ${themeConfig.colors.blue}">Digital Signage</span> Es una tecnología para proporcionar la información necesaria a través de dispositivos de visualización digital instalados en lugares públicos.`,
        'whatASmartplayer.p2':
            'Los dispositivos de visualización digital incluyen pantallas, Menús, pantallas LED, quioscos interactivos, paredes de video, tabletas, equipos de audio, etc.',
        'whatASmartplayer.p3':
            'En este momento, esta tecnología se está desarrollando activamente y se utiliza con eficacia en muchas industrias. Reemplaza el formato de visualización de publicidad actual: materiales de punto de venta estáticos, cajas de luz',
        'whatASmartplayer.descriptionTitle': 'Soluciones populares',
        'whatASmartplayer.listItem1': 'Proyecte su contenido publicitario',
        'whatASmartplayer.listItem2': 'Transmisión de audio programada',
        'whatASmartplayer.listItem3': 'Muestra el valor de sus productos u ofertas promocionales',
        'whatASmartplayer.listItem4': 'Quioscos de autoservicio o navegación en centros comerciales',
        'whatASmartplayer.listItem5': 'Tabla de cambio de divisas o horarios de vuelo',
        'whatASmartplayer.bigDescription': `La plataforma <span style="color: ${themeConfig.colors.blue}">SmartPlayer</span> es un software universal para implementar estas soluciones. Con la ayuda de nuestro software, puede publicar rápidamente el contenido necesario desde cualquier lugar del mundo y controlar el rendimiento del equipo.`,
        // Табы
        'tabs.title': '¿Cómo funciona SmartPlayer?',
        // Создание
        'tabs.create.title': 'Sube el contenido necesario a la plataforma y edítalo',
        'tabs.create.listTitle': 'Oportunidades:',
        'tabs.create.listItems':
            'Multi-zona <br/> Tickers <br/> RSS alimentación <br/> HTML <br/> visualización de páginas marco-web',
        // Управление
        'tabs.control.title': 'Agregar contenido a la programación',
        'tabs.control.listTitle': 'Oportunidades:',
        'tabs.control.listItems':
            'Crear transmisiones <br/> Contenido programado <br/> Agrupar dispositivos por ubicaciones de transmisión',
        // Проигрывание
        'tabs.play.title': 'Instalar licencias y descargar campañas en dispositivos',
        'tabs.play.listTitle': 'Oportunidades:',
        'tabs.play.listSubTitle': 'Gestión en 2 opciones:',
        'tabs.play.listItem1': 'Cloud SaaS <br/> Native Server',
        'tabs.play.listItem2': 'Plataforma cruzada <br/> Auto-Actualización',
        // Аналитика
        'tabs.analytic.title': 'Hacer permisos y monitorear el monitoreo y análisis',
        'tabs.analytic.listTitle': 'Oportunidades:',
        'tabs.analytic.listItems':
            'Diferenciación por roles <br/> Diferenciación por zonas de contenido <br/> Diferenciación por dispositivo y lugar de emisión',
        watchAVideo: 'Ver un video',
        feedbackButtonText: 'Contáctenos',
        feedbackTitle: 'Gracias por su evaluación',
        informationManagement: 'Sistema de visualización y gestión de contenido educativo',
        modules: 'Módulos',
        informaM1: 'Sistema de visualización y gestión de contenido educativo',
        informaM2: 'Composición del subsistema',
        informaM3:
            'El sistema de visualización y gestión de contenido educativo proporciona reproducción de contenido multimedia en dispositivos (en lo sucesivo denominados medios de visualización de información). Los medios para mostrar información pueden ser varios tipos de dispositivos que se ejecutan bajo los sistemas operativos Tizen OS versión 6.0 y superior, Android OS versión 5.0 y superior.',
        informaM4: 'El subsistema consta de los siguientes módulos:',
        informaM5:
            'El módulo "Display Control Server" está diseñado para el registro y la gestión centralizada de los módulos "Smart Client" instalados en los medios de visualización; El módulo "Smart Client", que es un software instalado en la herramienta de visualización de información.',
        informaM6: 'Servidor de control de visualización',
        informaM7:
            'El módulo Servidor de control de herramientas de visualización implementa las siguientes funciones:',
        informaM8:
            'registro de medios de visualización de información en los que está instalado el módulo Smart Client;',
        informaM9:
            'ver la lista de medios registrados para mostrar información con la posibilidad de ordenar y filtrar;',
        informaM10:
            'mostrar el estado de conexión de los medios de visualización de información al servidor de gestión;',
        informaM11: 'buscar medios para mostrar información;',
        informaM12: 'agregar, eliminar, editar medios de visualización de información;',
        informaM13: 'agregar, eliminar, editar grupos de medios de visualización de información;',
        informaM14: 'ver información ampliada sobre los medios de visualización de información;',
        informaM15:
            'cargar y eliminar contenido a través de la carpeta del maestro para mostrar en la herramienta de visualización de información;',
        informaM16:
            'la capacidad de seleccionar una carpeta para descargar contenido y administrar la estructura de carpetas con contenido;',
        informaM17:
            'generación de informes sobre el funcionamiento de las instalaciones de visualización de información, contenido reproducido;',
        informaM18:
            'gestión (vista previa en su cuenta personal) de reproducción de contenido en dispositivos de visualización;',
        informaM19: 'soporte de cuenta personal para los siguientes idiomas de visualización: kazajo, ruso, inglés.',
        informaM20: 'El módulo proporciona los siguientes roles de usuario:',
        informaM21: 'Administrador del subsistema',
        informaM22: 'Administrador de organizaciones educativas (en adelante Administrador PA)',
        informaM23: 'Trabajador docente',
        informaM24:
            'El administrador del subsistema y el administrador del TOE tienen acceso a todas las funciones enumeradas anteriormente. El administrador del subsistema tiene acceso a la lista completa de herramientas de visualización de información registradas, y el Administrador de OO tiene acceso solo a las herramientas de visualización de información relacionadas con la organización educativa que administra.',
        informaM25: 'La siguiente funcionalidad está disponible para el profesor:',
        informaM26:
            'cargar y eliminar contenido a través de la carpeta del maestro para mostrar en la herramienta de visualización de información;',
        informaM27:
            'la capacidad de seleccionar una carpeta para descargar contenido y administrar la estructura de carpetas con contenido;',
        informaM28:
            'gestión (vista previa en su cuenta personal) de reproducción de contenido en la herramienta de visualización de información.',
        informaM29: 'Cliente inteligente',
        informaM30: 'Son comunes',
        informaM31:
            'El módulo "Smart Client" está diseñado para controlar directamente la reproducción de contenido en dispositivos de visualización de información bajo el control de los sistemas operativos especificados en la cláusula 1.1 de este documento. El módulo "Smart Client" debe implementarse como una aplicación y trabajar en herramientas de visualización.',
        informaM32:
            'La instalación del módulo Smart Client proporciona herramientas tradicionales y fáciles de usar para la plataforma para la que se desarrolló la aplicación. Los métodos de instalación de aplicaciones incluyen la instalación de la aplicación desde un archivo. Después de la instalación y el lanzamiento, el módulo "Smart Client" debe garantizar el proceso de registro de la herramienta de visualización de información en el "Servidor de control de la herramienta de visualización". Después del registro, el módulo Smart Client realiza las siguientes funciones:',
        informaM33: 'configuración inicial y activación de la herramienta de visualización,',
        informaM34: 'autorización de un trabajador pedagógico sobre los medios de visualización de información,',
        informaM35:
            'mostrar páginas web ingresadas en el directorio "Display Control Server" en el navegador "Smart Client". Las páginas web representadas deben admitir el elemento web iFrame. Las páginas web mostradas deben estar escritas con soporte para las versiones M76 y M85 del motor web,',
        informaM36: 'ver la lista de archivos,',
        informaM37: 'reproducción de archivos,',
        informaM38: 'lanzamiento de la aplicación oficial del fabricante de hardware de "Smart Client",',
        informaM39: 'mostrar la señal entrante de la fuente HDMI.',
        informaM40: 'Configuración inicial y activación del renderizador',
        informaM41:
            'El módulo "Smart Client" proporciona el modo de configuración y activación inicial. Este modo se enciende al inicio de la herramienta de visualización si no se ha activado. El módulo Smart Client debe permanecer en el modo de configuración y activación inicial hasta que el visualizador se active correctamente. En el modo de configuración inicial, el módulo "Smart Client" busca una conexión con el módulo "Display Control Server". Si no hay conexión, el módulo Smart Client informa al usuario al respecto. Tras una conexión exitosa al módulo "Display Control Server", se muestra un código de activación en la pantalla de la herramienta de visualización. Después de activar la herramienta de visualización, el módulo Smart Client sale del modo de configuración y activación inicial y proporciona acceso a otras funciones. Hasta que se complete el proceso de activación, otras funciones del módulo no estarán disponibles para el usuario.',
        informaM42: 'Mostrar páginas web en un navegador',
        informaM43:
            'El módulo "Smart Client" brinda la posibilidad de visualizar contenidos a través de un navegador de Internet instalado en el dispositivo de visualización de información (dependiendo del modelo y SO del dispositivo) que no requiere la instalación de software adicional. El navegador proporciona navegación web, ejecución de scripts JavaScript.',
        informaM44: 'Ver una lista de archivos',
        informaM45:
            'El módulo "Smart Client" brinda la capacidad de acceder a archivos y carpetas en el módulo "Display Control Server" para navegar a través de carpetas y archivos, así como para reproducir archivos de un formato compatible. El módulo "Smart Client" debe proporcionar navegación a través de archivos y carpetas de una unidad USB externa (si la función es compatible con el dispositivo). El módulo Smart Client proporciona reproducción del archivo seleccionado si el formato de archivo es compatible. Durante la navegación, los documentos que no puedan ser reproducidos por medio del módulo deben estar ocultos al usuario.',
        informaM46: 'Reproducción de archivos',
        informaM47:
            'La función de reproducción de archivos le permite reproducir archivos que tienen los siguientes formatos: jpeg, archivos de imagen png; archivos de video en formato mp4; archivos de audio en formato mp3; Archivos HTML y páginas web, de acuerdo con el párrafo 1.2.2.1 subpárrafo 3. Al reproducir archivos de audio y video, debe ser posible: pausar, reanudar la reproducción, rebobinar y rebobinar.',
        feedbackDescription:
            '¡Recibiste esta carta porque eres una persona maravillosa! Si la carta parece incorrecta, abra la versión web de la carta. Si cree que recibió la carta por error o contiene errores, escríbanos al respecto. '
    },
    kz: {
        unlimitedPoints: 'Қай жерде болмасын, шексіз нүктелер',
        priceListTariffs: 'Бағалар тізімі және тарифтер',
        companyName: 'Компанияның атауы',
        companySite: 'Компанияның сайты',
        accessServiceDevice: '<b>Қызметке кез келген құрылғыдан қол жеткізу</b><br/><br/>(компьютер, планшет, телефон)',
        devicesManageMobile:
            'Таратылған дүкендер желісіндегі әртүрлі өндірушілердің< /b >көптеген құрылғыларын<b> басқару',
        devicesManage:
            '</b>Таратылған дүкендер желісіндегі<br/><br/>әртүрлі өндірушілердің <br/><br/> көптеген құрылғыларын басқару<b>',
        crossPlatformMobile:
            '<b>SmartPlayer</b>барлық операциялық жүйелерді</b> бірыңғай басқару интерфейсіне біріктіретін кроссплатформалық платформа<b>болып табылады',
        crossPlatform:
            '<b>SmartPlayer</b><br/><br/> барлық операциялық жүйелерді<br/> <br/>бірыңғай басқару интерфейсіне біріктіретін кроссплатформалық платформа</b><br/> <br/>болып табылады',
        unlimitedNumber: 'Қай жерде болмасын,<br/> шексіз нүктелер',
        supportForNewProject: 'Ынтымақтастық және жаңа жобаларды іске қосу мәселелері бойынша бізге хабарласыңыз',
        supportForExistedProject: 'Іске асырылған жобаларды техникалық қолдау мәселелері бойынша бізге хабарласыңыз',
        supportAboutProducts: 'Осы телефон бойынша сіз бағдарламалық жасақтаманың құнын сұрай аласыз',
        mainDescription:
            'Кез келген күрделіліктегі мультимедиялық жобаларды іске асыруға және сату орындарында құрылғыларды орталықтандырылған басқаруға арналған бірыңғай платформа.',
        'menu.aboutUs': 'Біз туралы',
        'menu.prices': 'Бағалар',
        'menu.contacts': 'Байланыс деректері',
        'menu.documentation': 'Құжаттама',
        try: 'Байқап көр',
        tryForFree: 'Тегін байқап көр',
        theyTrustUs: 'Бізге сенім білдіреді',
        solutions: 'SmartPlayer қандай міндеттерді шешеді?',
        'solutions.slide1.title': 'Бейнеқабырға',
        'solutions.slide1.text1': 'Келушілермен тиімді байланыс арнасы',
        'solutions.slide1.text2':
            'Тауардың болуы, бағаның өзгеруі туралы ақпаратты автоматты түрде жаңарту үшін кассалық жүйелермен ықпалдасу',
        'solutions.slide1.text3':
            ' "Экраннан экранға қалқитын балық әсері" контентін синхрондау мәзір тақтасындағы науқанға қосымша назар аудартуға көмектеседі',
        'solutions.slide2.title': 'Бейнебақылау',
        'solutions.slide2.text1': 'Барлық камераларды бір сенімді жүйеге біріктіру',
        'solutions.slide2.text2': 'Тұтынушыларға қызмет көрсету сапасын жақсарту',
        'solutions.slide2.text3': 'Қауіпсіздікті қамтамасыз ету',
        'solutions.slide2.text4': 'Дүкендерге келуді бағалау',
        'solutions.slide2.text5': 'Кассалық аймақты бақылау',
        'solutions.slide2.text6': 'Экрандардағы жарнаманы таргеттеу',
        'solutions.slide2.text7': 'Аудиторияның портретін құру',
        'solutions.slide3.title': 'Тач панельдер',
        'solutions.slide3.text1':
            'E-commerce жүйелерімен ықпалдасу арқылы omnichannel механикасын пайдалануға мүмкіндік береді. Қолда жоқ тауарға басқа дүкеннен немесе қоймадан тапсырыс беру мүмкіндігі.',
        'solutions.slide3.text2':
            'Пайдаланушыларды өнімді өз бетінше зерттеуге тартуға, өнімнің қымбат нұсқаларын көру және ұсыныстарды көрсету мүмкіндігі арқылы up-sell, cross-sell жасауға мүмкіндік береді',
        'solutions.slide4.title': 'Кассалық экран',
        'solutions.slide4.text1':
            'Yandex/Mail.ru/Between Exchange программатик платформаларымен ықпалдасу есебінен қосымша монетизация',
        'solutions.slide4.text2': 'Әлеуметтік сервистер, мәселен, сурдоаудармаға арналған қолданба',
        'solutions.slide4.text3': 'Бейілділік талдауы/ Қызмет көрсету сапасын бағалау',
        'solutions.slide4.text4':
            'Арнайы ұсыныстарды/акцияларды көрсету мүмкіндігі. Көрсетілген тауарлардың сатылымының 5%-ға дейін өсуі',
        'solutions.slide4.text5':
            'Экранға қосымша назар аудару үшін экранда чекті/ соманы көрсету (кассалық жүйемен ықпалдасу) мүмкіндігі',
        'solutions.slide4.text6':
            'Алдыңғы сатып алуларға және ағымдағы себетке негізделе отырып (бейілділік жүйесімен ықпалдасу), дараланған ұсыныстарды көрсету арқылы орташа чекті ұлғайту',
        'solutions.slide5.title': 'Экрандар + Фондық музыка',
        'solutions.slide5.text1':
            'Залдағы экраннан бейнеқатардан тәуелсіз, фондық музыкасы бар дыбыс жолағын шығару мүмкіндігі. Қосымша құрылғы немесе лицензия қажет емес.',
        'solutions.slide6.title': 'Витриналық дисплейлер',
        'solutions.slide6.text1':
            'Назар аудартады, сату нүктесіндегі трафикті көбейтеді, брендтің танылуы мен есте қалуын арттырады',
        'solutions.slide6.text2':
            'Жарықтылықтың жоғарылауы және артқы жарықты динамикалық реттеу функциясы есебінен күндізгі және қараңғы уақытта бірдей тиімді жұмыс істей алады',
        whySmartplayer: 'Неліктен SmartPlayer?',
        contacts: 'Байланыс деректері',
        toContactUs: 'Бізбен хабарласу',
        name: 'аты-жөні',
        email: 'email',
        phone: 'Телефон',
        mail: 'Пошта',
        message: 'хабарлама',
        send: 'Жөнелту',
        agreeText: 'Мен жеке деректерімді өңдеуге келісім беремін',
        messageIsSent: 'Хабарлама жөнелтілуде...',
        messageSendError: 'Хабарлама жөнелтілген жоқ. Бізге тікелей поштамызға жазыңыз:',
        messageSent: 'Хабарлама жөнелтілді',
        contactsForCommunication: 'Байланыс деректері',
        address: 'Мекенжай',
        smartplayerOffice: 'Мәскеу қ., Дербеневская к-сі, 20-үй, 26-ғим., <br /> 6-кіреберіс, 4-қабат, №5 кабинет ',
        schedule: 'Жұмыс режимі',
        'mon.fri': 'дс-жм',
        'sat.sun': 'сб-жс',
        dayOff: 'Демалыс',
        videowall: 'Бейнеқабырға',
        music: 'Музыка',
        CCTV: 'Бейнебақылау',
        menuBoards: 'Мәзір тақталары',
        touchPanels: 'Тач-панельдер',
        checkoutScreens: 'Кассалық экрандар',
        displayDisplays: 'Витриналық дисплейлер',
        // Prices
        'prices.monthly': 'Айлық',
        'prices.early': 'Жылдық',
        'prices.subscription': 'жазылым',
        'prices.forOneDevice': '1 құрылғы үшін',
        'prices.smartSoundDesc': 'Аудионы ойнату және фондық дыбыстарды басқару',
        'prices.smartScreenDesc': 'Көптеген экрандарда күрделі визуалды контентті басқару',
        'prices.smartTouchDesc': 'Интерактивті контентті басқару, мультимедиялық трансляцияларды жасау және өңдеу',
        'prices.smartVideoWallDesc': 'Бейнеқабырғадағы визуалды контентті басқару',
        'prices.smartVideoAnalyticsDesc': 'Бейнедеректерді талдау және күрделі визуалды контентті басқару',
        'prices.smartVideoAnalyticsAndTouchDesc':
            'Бейнедеректерді талдау және күрделі визуалды және интерактивті контентті басқару',
        'prices.chooseAService': 'Қызметті таңдаңыз',
        devices: 'Құрылғылар',
        login: 'Кіру',
        demoModalDescription: `<span style="color: ${themeConfig.colors.blue}">сервис мүмкіндіктерін</span>қарағым келеді`,
        requestADemo: 'Демоны сұрату',
        demostrationDescription:
            'Сарапшы SmartPlayer таныстырылымын біздің ең жақсы дәстүрлерімізге сүйене отырып өткізеді',
        'demo.description1': 'егжей-тегжейлі және түсінікті тілмен',
        'demo.description2': 'мысалдар негізінде нақты тапсырмаларыңызды ескере отырып',
        'demo.description3': 'кез келген сұраққа жауап береді',
        'whatASmartplayer.title': `Digital Signage деген не және SmartPlayer не үшін керек?`,
        'whatASmartplayer.p1':
            'Digital Signage – бұл қоғамдық орындарда орнатылған цифрлық дисплей құрылғылары арқылы қажетті ақпаратты беру технологиясы.',
        'whatASmartplayer.p2':
            'Цифрлық дисплей құрылғылары - экрандар, мәзір тақталары, жарықдиодты LED экрандар, интерактивті дүңгіршектер, бейнеқабырғалар, планшеттер, аудиожабдықтар және т.б.',
        'whatASmartplayer.p3':
            'Қазіргі кезде бұл технология белсенді дамып келеді және көптеген салаларда тиімді қолданылады. Ол жарнаманы көрсетудің заманауи форматын - статикалық Pos материалдарын, лайтбокстарды ауыстырады.',
        'whatASmartplayer.descriptionTitle': 'Танымал шешімдер',
        'whatASmartplayer.listItem1': 'Сіздің жарнамалық бейнеконтентіңізді трансляциялау',
        'whatASmartplayer.listItem2': 'Аудиожазбаларды кесте бойынша трансляциялау',
        'whatASmartplayer.listItem3': 'Сіздің өнімдеріңіздің немесе науқандық ұсыныстарыңыздың құнын көрсету',
        'whatASmartplayer.listItem4': 'Өзіне-өзі қызмет көрсету дүңгіршектері немесе Сауда орталығындағы навигация',
        'whatASmartplayer.listItem5': 'Валюта айырбастау тақтасы немесе рейстер кестесі',
        'whatASmartplayer.bigDescription':
            'SmartPlayer платформасы осындай шешімдерді жүзеге асыруға арналған әмбебап бағдарламалық жасақтама болып табылады. Біздің бағдарламалық жасақтамамыздың көмегімен Сіз әлемнің кез келген нүктесінен қажетті контентті тез жүктеп, жабдықтың жұмысын бақылай аласыз.',

        // Табтар
        'tabs.title': 'SmartPlayer жүйесі қалай жұмыс істейді',

        // Құру
        'tabs.create.title': 'Қажетті контентті редакторға жүктеп, оны өңдеңіз',
        'tabs.create.listTitle': 'Мүмкіндіктер:',
        'tabs.create.listItems':
            'Көп аймақтылық<br/>Жүгіртпе жол<br/>RSS таспасы<br/>HTML<br/>web-frame <br/>беттерді көрсету',

        // Басқару
        'tabs.control.title': 'Кестеге өңделген контентті қосыңыз',
        'tabs.control.listTitle': 'Мүмкіндіктер:',
        'tabs.control.listItems':
            'Трансляциялар жасау<br/>Контентті <br/> кесте бойынша көрсету<br/>Құрылғыларды хабар тарату<br/>орындары бойынша топтастыру',

        // Ойнату
        'tabs.play.title': 'Құрылғыларға лицензияларды орнатып, трансляцияларды жүктеңіз',
        'tabs.play.listTitle': 'Мүмкіндіктер:',
        'tabs.play.listSubTitle': '2 нұсқада басқару:',
        'tabs.play.listItem1': 'Бұлтты SaaS<br/>Жекеменшік сервер',
        'tabs.play.listItem2': 'Кроссплатформалылық<br/>Автожаңару',

        // Талдау
        'tabs.analytic.title': 'Қол жеткізу құқықтарын ажыратып, мониторингтеу <br/>және талдау барысын қадағалаңыз',
        'tabs.analytic.listTitle': 'Мүмкіндіктер:',
        'tabs.analytic.listItems':
            'Рөлдер бойынша ажырату<br/>Контент аймақтары бойынша ажырату<br/>Құрылғылар <br/>мен хабар тарату орындары бойынша ажырату',

        watchAVideo: 'Бейнежазбаны қара',
        feedbackButtonText: 'Бізге жазу',
        feedbackTitle: 'Пікіріңіз үшін рақмет!',
        informationManagement: 'Білім беру мазмұнын көрсету және басқару жүйесі',
        modules: 'Модульдер',
        informaM1: 'Білім беру мазмұнын көрсету және басқару жүйесі',
        informaM2: 'Ішкі жүйенің құрамы',
        informaM3:
            'Білім беру мазмұнын көрсету және басқару жүйесі ішкі жүйесі құрылғыларда мультимедиялық мазмұнды ойнатуды қамтамасыз етеді (бұдан әрі – ақпаратты көрсету құралы). Ақпаратты көрсету құралдары Tizen OS 6.0 және одан жоғары нұсқасы, Android ОЖ 5.0 және одан жоғары нұсқасы операциялық жүйелерде жұмыс істейтін әртүрлі құрылғылар болуы мүмкін.',
        informaM4: 'Ішкі жүйе келесі модульдерден тұрады:',
        informaM5:
            '«Дисплейді басқару сервері» модулі дисплей тасымалдағышында орнатылған «Smart Client» модульдерін тіркеуге және орталықтандырылған басқаруға арналған; Ақпаратты көрсету құралына орнатылған бағдарламалық құрал болып табылатын «Smart Client» модулі.',
        informaM6: 'Басқару серверін көрсету',
        informaM7: 'Дисплей құралдарының басқару сервері модулі келесі функцияларды жүзеге асырады:',
        informaM8: 'Smart Client модулі орнатылған ақпаратты көрсету құралдарын тіркеу;',
        informaM9: 'сұрыптау және сүзгілеу мүмкіндігі бар ақпаратты бейнелеудің тіркелген құралдарының тізімін қарау;',
        informaM10: 'ақпаратты көрсету құралдарының басқару серверіне қосылу күйін көрсету;',
        informaM11: 'ақпаратты бейнелеу құралдарын іздеу;',
        informaM12: 'ақпаратты бейнелеу құралдарын қосу, жою, өңдеу;',
        informaM13: 'ақпаратты бейнелеу құралдарының топтарын қосу, жою, өңдеу;',
        informaM14: 'ақпаратты көрсету құралдары туралы кеңейтілген ақпаратты қарау;',
        informaM15: 'ақпаратты көрсету құралында көрсету үшін мұғалімнің папкасы арқылы мазмұнды жүктеу және жою;',
        informaM16:
            'мазмұнды жүктеп алу үшін қалтаны таңдау және мазмұны бар қалталардың құрылымын басқару мүмкіндігі;',
        informaM17: 'ақпаратты көрсету құралдарының жұмысы, қайта шығарылатын контент туралы есептерді қалыптастыру;',
        informaM18: 'дисплей құрылғыларында мазмұнды ойнатуды басқару (жеке кабинетте алдын ала қарау);',
        informaM19: 'келесі дисплей тілдері үшін жеке кабинетті қолдау: қазақ, орыс, ағылшын.',
        informaM20: 'Модуль келесі пайдаланушы рөлдерін қамтамасыз етеді:',
        informaM21: 'Ішкі жүйе әкімшісі',
        informaM22: 'Білім беру ұйымдарының әкімшісі (бұдан әрі – ҚБ Әкімшісі)',
        informaM23: 'Педагог қызметкер',
        informaM24:
            'Ішкі жүйе әкімшісі мен TOE әкімшісі жоғарыда аталған барлық функцияларға қол жеткізе алады. Ішкі жүйе әкімшісінің тіркелген ақпаратты көрсету құралдарының толық тізіміне, ал OO әкімшісінің өзі басқаратын білім беру ұйымына қатысты ақпаратты көрсету құралдарына ғана рұқсаты бар.',
        informaM25: 'Мұғалімге келесі функциялар қолжетімді:',
        informaM26: 'ақпаратты көрсету құралында көрсету үшін мұғалімнің папкасы арқылы мазмұнды жүктеу және жою;',
        informaM27:
            'мазмұнды жүктеп алу үшін қалтаны таңдау және мазмұны бар қалталардың құрылымын басқару мүмкіндігі;',
        informaM28: 'ақпаратты көрсету құралында мазмұнды ойнатуды басқару (жеке кабинетте алдын ала қарау).',
        informaM29: 'Ақылды клиент',
        informaM30: 'Жалпы',
        informaM31:
            '«Smart Client» модулі осы құжаттың 1.1 тармағында көрсетілген операциялық жүйелердің бақылауындағы ақпаратты көрсету құрылғыларында мазмұнды ойнатуды тікелей басқаруға арналған. «Smart Client» модулі қосымша ретінде іске асырылуы және дисплей құралдарымен жұмыс істеуі керек.',
        informaM32:
            'Smart Client модулін орнату қолданба әзірленген платформа үшін ыңғайлы және дәстүрлі құралдарды қамтамасыз етеді. Қолданбаны орнату әдістері қолданбаны файлдан орнатуды қамтиды. Орнатып, іске қосқаннан кейін «Smart Client» модулі «Дисплей құралын басқару серверінде» ақпаратты көрсету құралын тіркеу процесін қамтамасыз етуі керек. Тіркелгеннен кейін Smart Client модулі келесі функцияларды орындайды:',
        informaM33: 'дисплей құралын бастапқы орнату және іске қосу,',
        informaM34: 'педагогикалық қызметкердің ақпаратты көрсету құралдарына рұқсаты;',
        informaM35:
            '«Smart Client» браузеріндегі «Дисплейді басқару сервері» каталогына енгізілген веб-беттерді көрсету. Көрсетілген веб-беттер iFrame веб-элементін қолдауы керек. Көрсетілген веб-беттер веб-қозғалтқыштың M76 және M85 нұсқаларын қолдау арқылы жазылуы керек,',
        informaM36: 'файлдар тізімін қарау,',
        informaM37: 'файлды ойнату,',
        informaM38: '«Smart Client» компаниясының аппараттық құрал өндірушісінің ресми қосымшасын іске қосу,',
        informaM39: 'HDMI көзінен келетін кіріс сигналын көрсету.',
        informaM40: 'Рендерерді бастапқы орнату және белсендіру',
        informaM41:
            '«Smart Client» модулі бастапқы орнату және белсендіру режимін қамтамасыз етеді. Бұл режим, егер ол іске қосылмаған болса, дисплей құралының басында қосылады. Smart Client модулі дисплей сәтті іске қосылғанша бастапқы орнату және белсендіру режимінде қалуы керек. Бастапқы орнату режимінде «Smart Client» модулі «Дисплейді басқару сервері» модулімен қосылымды тексереді. Егер байланыс болмаса, Smart Client модулі пайдаланушыға бұл туралы хабарлайды. «Дисплейді басқару сервері» модуліне сәтті қосылғаннан кейін дисплей құралының экранында белсендіру коды көрсетіледі. Дисплей құралын іске қосқаннан кейін Smart Client модулі бастапқы орнату және белсендіру режимінен шығып, басқа функцияларға қол жеткізуді қамтамасыз етеді. Белсендіру процесі аяқталмайынша, модульдің басқа функциялары пайдаланушыға қол жетімді емес.',
        informaM42: 'Веб-беттерді шолғышта көрсету',
        informaM43:
            '«Smart Client» модулі қосымша бағдарламалық жасақтаманы орнатуды қажет етпейтін ақпаратты көрсету құрылғысында (құрылғы моделіне және ОЖ-не байланысты) орнатылған интернет-браузер арқылы мазмұнды қарау мүмкіндігін қамтамасыз етеді. Браузер веб-шолғышты, JavaScript сценарийлерінің орындалуын қамтамасыз етеді.',
        informaM44: 'Файлдар тізімін қарау',
        informaM45:
            '«Smart Client» модулі қалталар мен файлдар арасында шарлау, сондай-ақ қолдау көрсетілетін пішімдегі файлдарды ойнату үшін «Дисплейді басқару сервері» модуліндегі файлдар мен қалталарға қол жеткізу мүмкіндігін береді. «Smart Client» модулі сыртқы USB дискінің файлдары мен қалталары бойынша шарлауды қамтамасыз етуі керек (егер бұл функцияға құрылғы қолдау көрсетсе). Smart Client модулі файл пішіміне қолдау көрсетілсе, таңдалған файлды ойнатуды қамтамасыз етеді. Шарлау кезінде модуль арқылы көшіруге болмайтын құжаттарды пайдаланушыдан жасыру керек.',
        informaM46: 'Файлды ойнату',
        informaM47:
            'Файлды ойнату функциясы келесі пішімдері бар файлдарды ойнатуға мүмкіндік береді: jpeg, png кескін файлдары; mp4 форматындағы бейне файлдар; mp3 форматындағы аудио файлдар; HTML файлдары және веб-беттер, 1.2.2.1-тармақтың 3-тармақшасына сәйкес. Аудио және бейне файлдарды ойнату кезінде: кідірту, ойнатуды жалғастыру, кері айналдыру және кері айналдыру мүмкіндігі болуы керек.',
        feedbackDescription:
            'Осы хатты алған болсаңыз, демек, сіз керемет адамсыз! Егер хат дұрыс көрінбесе, хаттың веб-нұсқасын ашыңыз. Егер Сіз хатты қате алдым деп ойласаңыз немесе онда қателер тапсаңыз, бізге бұл туралы жазуыңызды өтінеміз. '
    }
}
